import { IonButton, IonContent, IonPage } from "@ionic/react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { ParentProfile } from "../../../components/ParentProfile";
import { useParents } from "../../../providers/ParentsProvider";
import { EditParent } from "./components/EditParent";
import { useAuth } from "../../../providers/Auth.provider";
import { ROLES } from "../../../constants";

interface UserDetailPageProps
    extends RouteComponentProps<{
        email: string;
    }> {
}

export const ParentDetails: FC<UserDetailPageProps> = ({ match }) => {
    const [isEdit, setIsEdit] = useState(false)
    const location = useLocation()
    const { user } = useAuth()
    const { parentDetails, fetchParentDetails, clearParentDetails, parents } = useParents()

    const state = location?.state as { parent: string }

    useEffect(() => {
        (async () => {
            await fetchParentDetails(match.params.email, parents.find(_parent => _parent.email === match.params.email)?.name!)
        })()

        return () => clearParentDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    const onEditSuccess = async () => {
        await fetchParentDetails(parentDetails?.parent?.parent!, parents?.find(_parent => _parent.email === parentDetails?.parent?.parent)?.name!)
        setIsEdit(false)
    }

    return (
        <IonPage>
            <IonContent className="ion-padding">
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <h2>Parent Details</h2>
                    {user.role === ROLES.TEACHER.CODE && (
                        <IonButton onClick={() => setIsEdit(edit => !edit)}>{isEdit ? 'Cancel' : 'Edit'}</IonButton>
                    )}
                </div>
                {isEdit ? (
                    <div className="ion-margin-top">
                        <EditParent parent={parentDetails?.parent!} onSuccess={onEditSuccess}/>
                    </div>
                ) : (
                    <ParentProfile parent={parentDetails?.parent!} students={parentDetails?.students}/>
                )}
            </IonContent>
        </IonPage>
    )
}