import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonLabel,
    IonModal,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { addOutline, addSharp, closeOutline, closeSharp } from "ionicons/icons";
import { useState } from "react";
import { AssignLearningForm } from "./AssignLearningForm";
import { useAuth } from "../../../../providers/Auth.provider";
import { ROLES } from "../../../../constants";

type IProps = {
    studentName: string
    studentId: number
    onSuccess: () => void
}

export const AssignLearning = ({ studentName, studentId, onSuccess }: IProps) => {
    const [show, setShow] = useState(false)

    const { user } = useAuth()

    const close = () => {
        setShow(false)
    }

    if (user.role !== ROLES.TEACHER.CODE) {
        return null
    }

    const _onSuccess = () => {
        close()
        onSuccess()
    }

    return (
        <>
            <IonButton onClick={() => setShow(true)}>
                <IonIcon icon={addOutline} ios={addSharp}/>
                <IonLabel>Add</IonLabel>
            </IonButton>
            <IonModal isOpen={show} onDidDismiss={() => setShow(false)}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Assign Learning for {studentName}</IonTitle>
                        <IonButtons slot="start">
                            <IonButton onClick={() => close()}>
                                <IonIcon icon={closeOutline} ios={closeSharp}/>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    {!show ? null : (
                        <AssignLearningForm studentId={studentId} onSuccess={_onSuccess} />
                    )}
                </IonContent>
            </IonModal>
        </>
    )
}