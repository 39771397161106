import { IonAlert } from "@ionic/react";
import { ICurriculumDelete, ICurriculumRequestForm } from "../../../../interfaces/request/CurriculumRequest";
import { useAuth } from "../../../../providers/Auth.provider";
import { useFetch } from "../../../../hooks";
import { REQUEST_TYPE } from "../../../../constants";
import { IGenericResponse } from "../../../../interfaces/response";
import { getEnumLabel } from "../../../../helpers";
import { CurriculumType } from "../../../../constants/CurriculumType";

type IProps = {
    curriculum: ICurriculumRequestForm
    show: boolean
    onConfirm: () => void
    onCancel: () => void
}

export const DeleteCurriculum = ({ show, onCancel, onConfirm, curriculum }: IProps) => {
    const { user, generateHmac } = useAuth()
    const { post } = useFetch()

    const doDelete = async () => {
        const timestamp = Date.now().toString()
        const secret = generateHmac(timestamp)

        const payload: ICurriculumDelete = {
            id: curriculum.id!,
            email: user.email,
            role: user.role!,
            timestamp,
            secret
        }

        await post<IGenericResponse, ICurriculumDelete>(REQUEST_TYPE.DELETE_CURRICULUM, payload)

        onConfirm()
    }

    if (!curriculum) {
        return null
    }

    return (
        <IonAlert
            isOpen={show}
            header="Delete Curriculum"
            onDidDismiss={() => onCancel()}
            message={`Are you sure want to delete ${getEnumLabel(CurriculumType, curriculum.type)} ${curriculum.name}?`}
            buttons={[
                {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => {
                        onCancel()
                    }
                },
                {
                    text: 'Delete',
                    role: 'destructive',
                    handler: async () => {
                        await doDelete()
                    }
                }
            ]}
        />
    )
}