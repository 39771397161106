import { createContext, PropsWithChildren, useContext, useState } from "react";
import { REQUEST_TYPE } from "../constants";
import { useAuth } from "./Auth.provider";
import { useFetch } from "../hooks";
import { StudentDetailsBody } from "../interfaces/data/StudentDetails";
import { IParentDetails, IStudentResponse, ParentDetailsResponse } from "../interfaces/response";
import { ParentDetailsRequest, StudentRequestBody } from "../interfaces/request";

export interface IParent {
    email: string
    name: string
    family: string
}

type IResponseBody = {
    body: string
    id: string
    type: string
}

type IParentRequestData = {
    email: string
    role: number
    timestamp: string
    secret: string
}

type IParentDetailsPage = {
    parent?: {
        name: string
    } & IParentDetails
    students?: StudentDetailsBody[]
}

type IParentContext = {
    parents: IParent[]
    parentDetails?: IParentDetailsPage | undefined
    fetchParents: () => Promise<void>
    fetchParentDetails: (parentEmail: string, parentName: string) => Promise<void>
    clearParentDetails: () => void
}

const ParentsContext = createContext<IParentContext>({} as IParentContext)

export const useParents = () => useContext(ParentsContext)

export const ParentsProvider = (props: PropsWithChildren<any>) => {
    const [parents, setParents] = useState<IParent[]>([])
    const [parentDetails, setParentDetails] = useState<IParentDetailsPage>()

    const { generateHmac, user } = useAuth()

    const { post } = useFetch()

    const fetchParents = async () => {
        const timestamp = Date.now().toString()
        const secret = generateHmac(timestamp)

        const response = await post<IResponseBody, IParentRequestData>(REQUEST_TYPE.GET_PARENTS, {
            email: user.email,
            role: user.role!,
            timestamp,
            secret
        })

        setParents(JSON.parse(response?.body!))
    }

    const fetchParentDetails = async (parentEmail: string, parentName: string) => {
        const timestamp = Date.now().toString()
        const secret = generateHmac(timestamp)
        const response = await post<ParentDetailsResponse, ParentDetailsRequest>(REQUEST_TYPE.GET_PARENT_DETAILS, {
            email: user.email,
            parent: parentEmail,
            role: user.role!,
            secret,
            timestamp
        })

        const studentResponse = await post<IStudentResponse, StudentRequestBody>(REQUEST_TYPE.GET_STUDENT, {
            email: user.email,
            parent: response.body.email || response.body.parent,
            role: user.role!,
            secret,
            timestamp
        })

        setParentDetails({
            parent: {
                name: parentName,
                ...response.body
            },
            students: JSON.parse(studentResponse.body)
        })
    }

    return (
        <ParentsContext.Provider value={{
            parents,
            fetchParents,
            parentDetails,
            fetchParentDetails,
            clearParentDetails: () => setParentDetails(undefined)
        }}>
            {props.children}
        </ParentsContext.Provider>
    )
}