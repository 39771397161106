import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonModal,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { addOutline, addSharp, closeOutline, closeSharp, readerOutline, readerSharp } from "ionicons/icons";
import Markdown from "marked-react";
import { ILearningBody, IUploadResponse } from "../../../../interfaces/response";
import { useAuth } from "../../../../providers/Auth.provider";
import { REQUEST_TYPE, ROLES } from "../../../../constants";
import React, { useState } from "react";
import * as yup from "yup"
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment/moment";
import { MarkdownEditor, MarkdownEditorExtended } from "../../../../components/MarkdownEditor";
import { IUpdateLearning, IUploadBody } from "../../../../interfaces/request";
import { useFetch } from "../../../../hooks";
import { useStudent } from "../../../../providers/StudentsProvider";
import { useLoading } from "../../../../providers/Loading.provider";
import { MDXEditorMethods } from "@mdxeditor/editor";

const addProgressSchema = yup.object({
    progress: yup.string().required("Please fill progress"),
    date: yup.string().required("Date is required")
})

const editProgressSchema = yup.object({
    progress: yup.string().required("Please fill progress"),
})

type IProps = {
    learning?: ILearningBody
    setLearning: (value: ILearningBody | undefined) => void
}

export const LearningModal = ({ learning, setLearning }: IProps) => {
    const [showProgressForm, setShowProgressForm] = useState(false)
    const [showEditProgressForm, setShowEditProgressForm] = useState(false);
    const { user, generateHmac } = useAuth()
    const { fetchLearnings } = useStudent()
    const { isLoading } = useLoading()

    const { upload, post } = useFetch()

    const CreateForm = (schema : any) => {
        const mdxEditorRef = React.useRef<MDXEditorMethods>(null)
        const {
            register,
            reset,
            setValue,
            getValues,
            formState,
            handleSubmit
        } = useForm<{ date: string, progress: string }>({
            resolver: yupResolver(schema)
        })
    
        return { register, reset, setValue, getValues, formState, handleSubmit, mdxEditorRef }
    }

    const forms = {
        progressForm: CreateForm(addProgressSchema),
        editProgressForm: CreateForm(editProgressSchema)
    }

    const uploadImage = async (file: File): Promise<string> => {
        const timestamp = Date.now().toString()
        const secret = generateHmac(timestamp)
        const student = learning?.studentId + "_" + learning?.id; // used for filename prefix
        const payload: IUploadBody = {
            email: user.email,
            role: user.role!,
            student: student,
            secret,
            timestamp
        }

        const response = await upload<IUploadResponse, IUploadBody>(payload, file)
        return response.imagePreviewUrl
    }

    const onProgressSubmit: SubmitHandler<{ date: string, progress: string }> = async (data, event) => {
        event?.preventDefault()

        const timestamp = Date.now().toString()
        const secret = generateHmac(timestamp)

        const notes = `### ${moment(data.date, 'YYYY-MM-DD').format('D MMMM YYYY')}\n\n${data.progress}\n\n${learning?.notes!}`

        const payload: IUpdateLearning = {
            email: user.email,
            id: learning?.id!,
            role: user.role!,
            notes,
            secret,
            timestamp
        }

        await post(REQUEST_TYPE.UPDATE_LEARNING, payload)

        await fetchLearnings(learning?.studentId!)

        setShowProgressForm(false)
        setLearning(undefined)
    }

    const onEditProgressSubmit: SubmitHandler<{ progress: string }> = async (data, event) => {
        event?.preventDefault()

        const timestamp = Date.now().toString()
        const secret = generateHmac(timestamp)
        const notes = data.progress;

        const payload: IUpdateLearning = {
            email: user.email,
            id: learning?.id!,
            role: user.role!,
            notes,
            secret,
            timestamp
        }

        await post(REQUEST_TYPE.UPDATE_LEARNING, payload)

        await fetchLearnings(learning?.studentId!)

        setShowProgressForm(false)
        setLearning(undefined)
    }

    function onDismiss() {
        setLearning(undefined)
        forms.progressForm.reset()
        forms.editProgressForm.reset()
        setShowProgressForm(false);
        setShowEditProgressForm(false);
    }

    return (
        <IonModal isOpen={learning !== undefined} onDidDismiss={() => onDismiss()}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{learning?.curriculumName}</IonTitle>
                    <IonButtons slot="start">
                        <IonButton onClick={() => setLearning(undefined)}>
                            <IonIcon icon={closeOutline} ios={closeSharp}/>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonList lines="none">
                    <IonItem>
                        <IonLabel>
                            <h3>Area</h3>
                            <p>{learning?.curriculumArea}</p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                            <h3>Subject</h3>
                            <p>{learning?.curriculumSubject}</p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                            <h3>Description</h3>
                            <Markdown value={learning?.curriculumDesc}/>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                            <h3>Student</h3>
                            <p>{learning?.studentName}</p>
                        </IonLabel>
                    </IonItem>

                    <IonItem>
                        <IonLabel>
                            <h3>Teacher</h3>
                            <p>{learning?.teacherName}</p>
                        </IonLabel>
                    </IonItem>

                    <IonListHeader className="ion-margin-top" lines="inset" key="header">
                        <IonLabel>Progress</IonLabel>
                        {user.role === ROLES.TEACHER.CODE && (
                            <>
                            <IonButton onClick={() => {
                                        setShowProgressForm(true)
                                        setShowEditProgressForm(false)
                                    }}>
                                <IonIcon icon={addOutline} ios={addSharp}/>
                                <IonLabel>Add</IonLabel>
                            </IonButton>
                            <IonButton onClick={() => {
                                    /**
                                     * workaround to solve onchange event stop working
                                     */
                                    setTimeout(() => {
                                        forms.editProgressForm.mdxEditorRef.current?.focus()
                                        forms.editProgressForm.mdxEditorRef.current?.insertMarkdown("")
                                    }, 250);

                                    forms.editProgressForm.setValue("progress", learning!.notes)
                                    setShowProgressForm(false)
                                    setShowEditProgressForm(true)
                                }}>
                                <IonIcon icon={readerOutline} ios={readerSharp}/>
                                <IonLabel>Edit</IonLabel>
                            </IonButton>
                            </>
                        )}
                    </IonListHeader>
                    {showProgressForm && (
                        <form onReset={() => {
                            forms.progressForm.reset()
                            setShowProgressForm(false)
                        }} onSubmit={forms.progressForm.handleSubmit(onProgressSubmit)}>
                            <IonInput
                                className={`ion-margin-bottom ${forms.progressForm.formState.errors.date && 'ion-invalid'} ${forms.progressForm.formState.touchedFields.date && 'ion-touched'}`}
                                fill="outline"
                                label="Date"
                                labelPlacement="floating"
                                placeholder="Date"
                                type="date"
                                errorText={forms.progressForm.formState.errors.date?.message}
                                defaultValue={moment(moment()).format('YYYY-MM-DD')}
                                {...forms.progressForm.register("date")}
                            />

                            <MarkdownEditor
                                value={forms.progressForm.getValues("progress")}
                                onChange={(value) => forms.progressForm.setValue("progress", value)}
                                label="Note"
                                imageUploadHandler={uploadImage}
                            />

                            <IonButtons slot="end">
                                <IonButton disabled={isLoading} type="reset" color="danger">Cancel</IonButton>
                                <IonButton disabled={isLoading} type="submit">Save</IonButton>
                            </IonButtons>
                        </form>
                    )}
                    {showEditProgressForm && (
                        <form onReset={() => {
                            forms.editProgressForm.reset()
                            setShowEditProgressForm(false)
                        }} onSubmit={forms.editProgressForm.handleSubmit(onEditProgressSubmit)}>
                            <MarkdownEditorExtended
                                value={forms.editProgressForm.getValues("progress")}
                                onChange={(value) => forms.editProgressForm.setValue("progress", value)}
                                label="Note"
                                imageUploadHandler={uploadImage}
                                mdxEditorRef={forms.editProgressForm.mdxEditorRef}
                            />

                            <IonButtons slot="end">
                                <IonButton disabled={isLoading} type="reset" color="danger">Cancel</IonButton>
                                <IonButton disabled={isLoading} type="submit">Save</IonButton>
                            </IonButtons>
                        </form>
                    )}
                    {!showEditProgressForm && (
                        <IonItem>
                            <IonLabel>
                                <Markdown value={learning?.notes}/>
                            </IonLabel>
                        </IonItem>
                    )}
                </IonList>
            </IonContent>
        </IonModal>
    )
}
