import { useGoogleLogin } from "@react-oauth/google";
import { COOKIE_NAMES, Method, SERVER } from "../../constants";
import { Cookies } from "../../helpers";
import { v4 as uuidv4 } from 'uuid';
import { useLoading } from "../../providers/Loading.provider";
import { useAuth } from "../../providers/Auth.provider";
import { useToast } from "../../providers/ToastProvider";

export const useLogin = () => {
    const { isLoading, setIsLoading } = useLoading()
    const { toast } = useToast()
    const { setSession } = useAuth()

    const doLogin = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: async loginResponse => {
            await createClientSecret(loginResponse.code)
        }
    })


    const createClientSecret = async (authCode: string) => {
        try {
            setIsLoading(true)
            const request = {
                method: Method.Post,
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    id: uuidv4(),
                    type: "otp.create",
                    body: {
                        type: 3,
                        length: 6
                    }
                })
            }

            const response = await fetch(SERVER.HOST, request).then(response => response.json())

            await createSession(authCode, response.body.id, response.body.secret)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const createSession = async (authCode: string, otpId: string, otpSecret: string) => {
        try {
            setIsLoading(true)

            let redirectUrl = window.location.protocol + '//' + window.location.hostname;
            if (window.location.port !== "") {
                redirectUrl = redirectUrl + ':' + window.location.port;
            }

            const request = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    "id": uuidv4(),
                    "type": "rona.app.authentication",
                    "body": {
                        "otpId": otpId,
                        "authCode": authCode,
                        "redirectUrl": redirectUrl
                    }
                })
            };

            const response = await fetch(SERVER.HOST, request).then(response => response.json())

            if (response.type === "rona.app.authentication") {
                toast('Logging In')

                setSession({
                    name: response.body.name,
                    email: response.body.email,
                    secret: otpSecret,
                    session: response.body.session,
                    roles: response.body.availableRoles
                })

                Cookies.setCookie(COOKIE_NAMES.NAME, response.body.name)
                Cookies.setCookie(COOKIE_NAMES.EMAIL, response.body.email)
                Cookies.setCookie(COOKIE_NAMES.SECRET, otpSecret)
                Cookies.setCookie(COOKIE_NAMES.SESSION, response.body.session)
                Cookies.setCookie(COOKIE_NAMES.ROLES, JSON.stringify(response.body.availableRoles))

                window.location.reload()
            } else {
                toast('Access Denied')
            }
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    return {
        isLoading,
        doLogin
    }
}