import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonFab,
    IonFabButton,
    IonHeader,
    IonIcon,
    IonInput,
    IonModal,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { addOutline, closeOutline, closeSharp } from "ionicons/icons";
import { useAuth } from "../../../../providers/Auth.provider";
import { IAddStudentRequestBody } from "../../../../interfaces/request";
import { useFetch } from "../../../../hooks";
import { Gender, REQUEST_TYPE, ROLES } from "../../../../constants";
import { IAddStudentResponse } from "../../../../interfaces/response";
import { useStudent } from "../../../../providers/StudentsProvider";
import { Typeahead } from "../../../../components/Typeahead";
import { useParents } from "../../../../providers/ParentsProvider";
import { enumToSelectOptions } from "../../../../helpers";
import { useLoading } from "../../../../providers/Loading.provider";
import { useToast } from "../../../../providers/ToastProvider";

type IAddStudentForm = {
    parent: string
    studentName: string
    birthdate: string
    gender: number
}

const schema = yup.object({
    parent: yup.string().email("Email format is invalid").required("Email is required"),
    studentName: yup.string().required("Student Name is required"),
    birthdate: yup.string().required("Birth Date is required"),
    gender: yup.number().required("Gender is required")
})

export const AddStudent = () => {
    const [isModalOpen, _setIsModalOpen] = useState(false)
    const { user, generateHmac } = useAuth()
    const { fetchStudents } = useStudent()
    const { parents, fetchParents } = useParents()
    const { post } = useFetch()
    const { isLoading } = useLoading()
    const {
        register,
        handleSubmit,
        reset,
        formState,
        getValues,
        setValue
    } = useForm<IAddStudentForm>({
        resolver: yupResolver(schema)
    })

    const { toast } = useToast()

    const onSubmit: SubmitHandler<IAddStudentForm> = async (data, event) => {
        event?.preventDefault()
        try {
            const timestamp = Date.now().toString()
            const secret = generateHmac(timestamp)

            const payload: IAddStudentRequestBody = {
                email: user.email,
                role: user.role!,
                timestamp,
                secret,
                ...data
            }

            const response = await post<IAddStudentResponse, IAddStudentRequestBody>(REQUEST_TYPE.ADD_STUDENT, payload)

            if (response.body.code === 200) {
                toast(`Student ${data.studentName} successfully added`)
                reset()
                setIsModalOpen(false)
                await fetchStudents()
            }
        } catch (e: any) {
            toast(e.message)
        }
    }

    const setIsModalOpen = (open: boolean) => {
        reset()
        _setIsModalOpen(open)
    }

    useEffect(() => {
        if (parents.length === 0 && user.role === ROLES.TEACHER.CODE) {
            (async () => {
                await fetchParents()
            })()
        }
        // eslint-disable-next-line
    }, [parents]);

    if (user.role !== ROLES.TEACHER.CODE) {
        return null
    }

    return (
        <>
            <IonFab vertical="bottom" horizontal="end">
                <IonFabButton onClick={() => setIsModalOpen(true)}>
                    <IonIcon icon={addOutline}/>
                </IonFabButton>
            </IonFab>
            <IonModal isOpen={isModalOpen} onDidDismiss={() => setIsModalOpen(false)}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Add Parent</IonTitle>
                        <IonButtons slot="start">
                            <IonButton onClick={() => setIsModalOpen(false)}>
                                <IonIcon icon={closeOutline} ios={closeSharp}/>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Typeahead
                            className={`ion-margin-bottom ${formState.errors.parent && 'ion-invalid'} ${formState.touchedFields.parent && 'ion-touched'}`}
                            items={parents.map(parent => ({ text: parent.name, value: parent.email }))}
                            label="Parent Email"
                            placeholder="Parent Email"
                            errorText={formState.errors.parent?.message}
                            showPreview
                            selectedItem={getValues("parent")}
                            onSelectionChange={(item) => setValue("parent", item)}
                        />
                        <IonInput
                            className={`ion-margin-bottom ${formState.errors.studentName && 'ion-invalid'} ${formState.touchedFields.studentName && 'ion-touched'}`}
                            fill="outline"
                            label="Name"
                            labelPlacement="floating"
                            placeholder="Name"
                            errorText={formState.errors.studentName?.message}
                            {...register("studentName")}
                        />

                        <IonInput
                            className={`ion-margin-bottom ${formState.errors.birthdate && 'ion-invalid'} ${formState.touchedFields.birthdate && 'ion-touched'}`}
                            fill="outline"
                            label="Birth Date"
                            type="date"
                            labelPlacement="floating"
                            placeholder="Birth Date"
                            errorText={formState.errors.birthdate?.message}
                            {...register("birthdate")}
                        />

                        <IonSelect
                            interface="popover"
                            className={`ion-margin-bottom ${formState.errors.gender && 'ion-invalid'} ${formState.touchedFields.gender && 'ion-touched'}`}
                            fill="outline"
                            label="Gender"
                            labelPlacement="floating"
                            placeholder="Gender"
                            {...register('gender')}
                        >
                            {enumToSelectOptions(Gender).map(option => (
                                <IonSelectOption value={option.value}
                                                 key={option.text.toLowerCase()}>{option.text}</IonSelectOption>
                            ))}
                        </IonSelect>
                        <IonButton className="ion-activable" expand="block" type="submit" disabled={isLoading}>
                            Save
                        </IonButton>
                    </form>
                </IonContent>
            </IonModal>
        </>
    )
}