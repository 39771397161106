import { IonButton, IonContent, IonIcon, IonLabel } from "@ionic/react";
import { useEffect, useState } from "react";
import { useAuth } from "../../../providers/Auth.provider";
import { PageWrapper } from "../../../components/PageWrapper";
import { ParentProfile } from "../../../components/ParentProfile";
import { useParents } from "../../../providers/ParentsProvider";
import { EditParent } from "../Parents/components/EditParent";
import { pencilOutline, pencilSharp } from "ionicons/icons";

export const Profile = () => {
    const { parentDetails, fetchParentDetails, clearParentDetails } = useParents()
    const [isEdit, setIsEdit] = useState(false)
    const { user } = useAuth()

    useEffect(() => {
        (async () => {
            await fetchParentDetails(user.email, user.name)
        })()
        return () => clearParentDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onEditSuccess = async () => {
        await fetchParentDetails(user.email, user.name)
        setIsEdit(false)
    }

    return (
        <PageWrapper name="Profile">
            <IonContent className="ion-padding">
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <h2>Profile</h2>
                    <IonButton onClick={() => setIsEdit(edit => !edit)} size="small">
                        <IonIcon icon={pencilOutline} ios={pencilSharp} slot="start"/>
                        <IonLabel>{isEdit ? 'Cancel' : 'Edit'}</IonLabel>
                    </IonButton>
                </div>
                {isEdit ? (
                    <div className="ion-margin-top">
                        <EditParent parent={parentDetails?.parent!} onSuccess={onEditSuccess}/>
                    </div>
                ) : (
                    <ParentProfile parent={parentDetails?.parent!} students={parentDetails?.students}/>
                )}
            </IonContent>
        </PageWrapper>
    )
}