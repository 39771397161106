import Menu from "../../components/Menu";
import { IonPage, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { HomeIndex } from "./Home";
import { ParentsIndex } from "./Parents";
import { Profile } from "./Profile";
import { StudentsIndex } from "./Students";
import { Route, RouteComponentProps } from "react-router-dom";
import { FC } from "react";
import { CurriculumsIndex } from "./Curriculums";
import { FormProvider, useForm } from "react-hook-form";

export const DashboardIndex: FC<RouteComponentProps> = ({ match }) => {
    const methods = useForm()
    return (
        <IonPage>
            <FormProvider {...methods}>
                <IonSplitPane contentId="main">
                    <Menu/>
                    <IonRouterOutlet id="main">
                        <Route path={`${match.url}/curriculums`} component={CurriculumsIndex}/>
                        <Route path={`${match.url}/parents`} component={ParentsIndex}/>
                        <Route path={`${match.url}/students`} component={StudentsIndex}/>
                        <Route path={`${match.url}/profile`} component={Profile} exact/>
                        <Route path={match.url} component={HomeIndex} exact/>
                    </IonRouterOutlet>
                </IonSplitPane>
            </FormProvider>
        </IonPage>
    )
}