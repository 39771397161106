import { IonItem, IonLabel, IonList, IonRefresher, IonRefresherContent, IonSearchbar, IonToolbar } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";
import { useParents } from "../../../providers/ParentsProvider";
import { useEffect, useState } from "react";
import { AddParent } from "./components/AddParent";
import { NoData } from "../../../components/NoData";

export const ParentsList = () => {
    const { parents, fetchParents } = useParents()
    const [searchKeyword, setSearchKeyword] = useState<string>("")
    const _parents = parents.filter(parent => parent.name.toLowerCase().includes(searchKeyword.toLowerCase()))

    useEffect(() => {
        (async () => {
            await fetchParents()
        })()
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <IonRefresher slot="fixed" onIonRefresh={fetchParents}>
                <IonRefresherContent/>
            </IonRefresher>
            <IonToolbar>
                <IonSearchbar debounce={100} onIonInput={(event) => setSearchKeyword(event?.target?.value!)}/>
            </IonToolbar>
            <IonList>
                {_parents.length === 0 && (
                    <IonItem lines="none">
                        <NoData />
                    </IonItem>
                )}

                {_parents.length > 0 && _parents.map(parent => (
                    <IonItem key={parent.email} button detail detailIcon={chevronForwardOutline}
                             routerLink={`/dashboard/parents/${parent.email}`}>
                        <IonLabel>
                            <h3>{parent.name}</h3>
                            <p>{parent.email}</p>
                        </IonLabel>
                    </IonItem>
                ))}
            </IonList>
            <AddParent/>
        </>
    )
}