import { createContext, PropsWithChildren, useContext, useState } from "react";
import { ITeacherData } from "../interfaces/data/Teacher";
import { useAuth } from "./Auth.provider";
import { useFetch } from "../hooks";
import { IGenericRequestBody } from "../interfaces/request";
import { IGenericResponse } from "../interfaces/response";
import { REQUEST_TYPE } from "../constants";

type ITeacherContext = {
    teachers: ITeacherData[]
    fetchTeachers: () => Promise<void>
    clearTeachers: () => void
}

const TeachersContext = createContext<ITeacherContext>({} as ITeacherContext)

export const useTeachers = () => useContext(TeachersContext)

export const TeachersProvider = ({ children }: PropsWithChildren) => {
    const [teachers, setTeachers] = useState<ITeacherData[]>([])

    const { user, generateHmac } = useAuth()
    const { post } = useFetch()

    const fetchTeachers = async () => {
        const timestamp = Date.now().toString()
        const secret = generateHmac(timestamp)

        const payload: IGenericRequestBody = {
            email: user.email,
            role: user.role!,
            timestamp,
            secret
        }

        const response = await post<IGenericResponse, IGenericRequestBody>(REQUEST_TYPE.GET_TEACHER, payload)

        setTeachers(JSON.parse(response.body))
    }

    return (
        <TeachersContext.Provider value={{
            teachers,
            fetchTeachers,
            clearTeachers: () => setTeachers([])
        }}>
            {children}
        </TeachersContext.Provider>
    )
}