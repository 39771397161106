import React from 'react';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from "@react-oauth/google";
import { IonReactRouter } from "@ionic/react-router";
import { IonRouterOutlet } from "@ionic/react";
import { LoadingProvider } from "./providers/Loading.provider";
import { AuthProvider } from "./providers/Auth.provider";
import { ParentsProvider } from "./providers/ParentsProvider";
import { StudentProvider } from "./providers/StudentsProvider";
import { ToastProvider } from "./providers/ToastProvider";
import { CurriculumsProvider } from "./providers/CurriculumsProvider";
import { createRoot } from "react-dom/client";
import "@mdxeditor/editor/style.css"
import { TeachersProvider } from "./providers/TeachersProvider";

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
    <React.StrictMode>
        <GoogleOAuthProvider clientId="116660682950-mnfugbovcu3nhnd2nue911hl8srbb3d1.apps.googleusercontent.com">
            <LoadingProvider>
                <ToastProvider>
                    <AuthProvider>
                        <ParentsProvider>
                            <CurriculumsProvider>
                                <StudentProvider>
                                    <TeachersProvider>
                                        <IonReactRouter>
                                            <IonRouterOutlet>
                                                <App/>
                                            </IonRouterOutlet>
                                        </IonReactRouter>
                                    </TeachersProvider>
                                </StudentProvider>
                            </CurriculumsProvider>
                        </ParentsProvider>
                    </AuthProvider>
                </ToastProvider>
            </LoadingProvider>
        </GoogleOAuthProvider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
