export const getEnumLabel = <T>(enumObject: T, value: T[keyof T], fallback?: string): string => {
    return Object.keys(enumObject as any).find(key => enumObject[key as keyof T] === value) || fallback || ""
}

export const enumToSelectOptions = <T>(enumObject: T): {text: string, value: string}[] => {
    // @ts-ignore
    return Object.keys(enumObject)
        .filter(key => isNaN(Number(key))) // Filter out numeric keys
        .map(key => ({
            text: key,
            value: enumObject[key as keyof typeof enumObject]
        }));
}