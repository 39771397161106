import { useLoading } from "../providers/Loading.provider";
import { v4 as uuidv4 } from "uuid";
import { SERVER } from "../constants";
import { useAuth } from "../providers/Auth.provider";
import { useToast } from "../providers/ToastProvider";

export const useFetch = () => {
    const { setIsLoading } = useLoading()
    const { signOut } = useAuth()
    const { toast } = useToast()

    const post = async <T, U>(type: string, data: U): Promise<T> => {
        try {
            setIsLoading(true)
            const request = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    id: uuidv4(),
                    type,
                    body: data
                })
            }
            const response = await fetch(SERVER.HOST, request)

            if (!response.ok) {
                // noinspection ExceptionCaughtLocallyJS
                throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);
            }

            const responseData = await response.json()

            if (responseData && ((responseData.type === type) || (responseData.body.code >= 200 && responseData.body.code < 300))) {
                return responseData
            } else {
                if (responseData.body.code === 401) {
                    await signOut?.()
                }
                // noinspection ExceptionCaughtLocallyJS
                throw new Error(responseData?.body?.message || 'Unexpected Response Type')
            }
        } catch (e: any) {
            toast(e.toString())
            throw e
        } finally {
            setIsLoading(false)
        }
    }

    const upload = async <T, U>(data: U, file: File): Promise<T> => {
        try {
            setIsLoading(true)
            const formData = new FormData()
            formData.append("file", file)

            const accessTokenJson = JSON.stringify(data)
            const accessToken = btoa(accessTokenJson)

            const response = await fetch(SERVER.UPLOAD_FILE, {
                method: "POST",
                headers: {
                    "X-Access-Token": accessToken
                },
                body: formData
            })

            if (response && response.ok) {
                return JSON.parse(await response.text()) as T
            } else if (response.status === 401) {
                await signOut()
                // noinspection ExceptionCaughtLocallyJS
                throw new Error("Unauthorized")
            } else {
                // noinspection ExceptionCaughtLocallyJS
                throw new Error('Unexpected Response Type')
            }
        } catch (e: any) {
            toast(e.toString())
            throw e
        } finally {
            setIsLoading(false)
        }
    }

    return {
        post,
        upload
    }
}
