import { useState } from "react";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonFab,
    IonFabButton,
    IonHeader,
    IonIcon,
    IonInput,
    IonModal,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { addOutline, addSharp, closeOutline, closeSharp } from "ionicons/icons";
import { SubmitHandler, useForm } from "react-hook-form";
import { REQUEST_TYPE } from "../../../../constants";
import { useAuth } from "../../../../providers/Auth.provider";
import { useLoading } from "../../../../providers/Loading.provider";
import { useFetch } from "../../../../hooks";
import { useParents } from "../../../../providers/ParentsProvider";
import * as yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup";
import { useToast } from "../../../../providers/ToastProvider";

type IAddParentForm = {
    parentEmail: string
    parentName: string
    parentFamily?: string
}

type IAddParentRequestBody = {
    email: string
    role: number
    timestamp: string
    secret: string
} & IAddParentForm

export interface IAddParentResponse {
    id: string
    type: string
    body: Body
}

export interface Body {
    code: number
    message: string
    parent: string
}

const schema = yup.object({
    parentName: yup.string().required("Name is required"),
    parentEmail: yup.string().email("Email format is invalid").required("Email is required"),
    parentFamily: yup.string().email("Relative Email format is invalid")
})

export const AddParent = () => {
    const [isModalOpen, _setIsModalOpen] = useState(false)

    const {
        register,
        handleSubmit,
        reset,
        formState
    } = useForm<IAddParentForm>({
        resolver: yupResolver(schema)
    })

    const { user, generateHmac } = useAuth()
    const { setIsLoading, isLoading } = useLoading()
    const { post } = useFetch()
    const { fetchParents } = useParents()

    const { toast } = useToast()

    const onSubmit: SubmitHandler<IAddParentForm> = async (data, event) => {
        event?.preventDefault()
        try {
            setIsLoading(true)
            const timestamp = Date.now().toString()
            const payload: IAddParentRequestBody = {
                email: user.email,
                role: user.role!,
                timestamp,
                secret: generateHmac(timestamp),
                ...data
            }

            const response = await post<IAddParentResponse, IAddParentRequestBody>(REQUEST_TYPE.ADD_PARENT, payload)
            if (response.body.code === 200) {
                toast(`Parent ${data.parentName} successfully added`)
                reset()
                setIsModalOpen(false)
                await fetchParents()
            }
        } catch (e: any) {
            toast(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    const setIsModalOpen = (open: boolean) => {
        reset()
        _setIsModalOpen(open)
    }

    return (
        <>
            <IonFab vertical="bottom" horizontal="end">
                <IonFabButton onClick={() => setIsModalOpen(true)}>
                    <IonIcon icon={addOutline} ios={addSharp}/>
                </IonFabButton>
            </IonFab>
            <IonModal isOpen={isModalOpen} onDidDismiss={() => setIsModalOpen(false)}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Add Parent</IonTitle>
                        <IonButtons slot="start">
                            <IonButton onClick={() => setIsModalOpen(false)}>
                                <IonIcon icon={closeOutline} ios={closeSharp}/>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <IonInput
                            className={`ion-margin-bottom ${formState.errors.parentName && 'ion-invalid'} ${formState.touchedFields.parentName && 'ion-touched'}`}
                            fill="outline"
                            label="Name"
                            labelPlacement="floating"
                            placeholder="Name"
                            errorText={formState.errors.parentName?.message}
                            {...register("parentName")}
                        />
                        <IonInput
                            className={`ion-margin-bottom ${formState.errors.parentEmail && 'ion-invalid'} ${formState.touchedFields.parentEmail && 'ion-touched'}`}
                            fill="outline"
                            label="Email"
                            labelPlacement="floating"
                            placeholder="Email"
                            errorText={formState.errors.parentEmail?.message}
                            {...register("parentEmail")}
                        />
                        <IonInput
                            className={`ion-margin-bottom ${formState.errors.parentFamily && 'ion-invalid'} ${formState.touchedFields.parentFamily && 'ion-touched'}`}
                            fill="outline"
                            label="Relative Email"
                            labelPlacement="floating"
                            placeholder="Relative Email"
                            errorText={formState.errors.parentFamily?.message}
                            {...register("parentFamily")}
                        />
                        <IonButton className="ion-activatable" expand="block" type="submit" disabled={isLoading}>
                            Save
                        </IonButton>
                    </form>
                </IonContent>
            </IonModal>
        </>
    )
}