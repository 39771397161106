import { IonButton, IonInput } from "@ionic/react";
import { useCurriculums } from "../../../../providers/CurriculumsProvider";
import { useEffect, useState } from "react";
import { ICurriculum } from "../../../../interfaces/data";
import * as yup from 'yup'
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { Typeahead } from "../../../../components/Typeahead";
import { useTeachers } from "../../../../providers/TeachersProvider";
import { useFetch } from "../../../../hooks";
import { useAuth } from "../../../../providers/Auth.provider";
import { IGenericResponseWithStatus } from "../../../../interfaces/response";
import { REQUEST_TYPE } from "../../../../constants";
import { IAssignLearningRequestBody, IAssignLearningRequestForm } from "../../../../interfaces/request";

const schema = yup.object({
    student: yup.number().required("Student is required"),
    curriculum: yup.number().required("Curriculum is required"),
    date: yup.string().required("Date is required"),
    teacher: yup.string().required("Teacher is required")
})

type IProps = {
    studentId: number
    onSuccess: () => void
}

export const AssignLearningForm = ({ studentId, onSuccess }: IProps) => {
    const [areas, setAreas] = useState<ICurriculum[]>([])
    const [subjects, setSubjects] = useState<ICurriculum[]>([])
    const [materials, setMaterials] = useState<ICurriculum[]>([])
    const [selectedArea, setSelectedArea] = useState<number>()
    const [selectedSubject, setSelectedSubject] = useState<number>()
    const [selectedMaterial, setSelectedMaterial] = useState<number>()

    const { fetchCurriculums, curriculums, clearCurriculums } = useCurriculums()
    const { teachers, fetchTeachers, clearTeachers } = useTeachers()
    const { user, generateHmac } = useAuth()
    const { post } = useFetch()

    const {
        reset,
        register,
        setValue,
        getValues,
        formState,
        handleSubmit
    } = useForm<IAssignLearningRequestForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            date: moment().format('YYYY-MM-DD'),
            student: studentId
        }
    })

    useEffect(() => {
        (async () => {
            await fetchCurriculums()
            await fetchTeachers()
        })()

        return () => {
            clearCurriculums()
            clearTeachers()
            reset()
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (curriculums.length > 0) {
            const _subjects = curriculums.map(area => area.items).flat()

            const _materials = _subjects.map(subject => subject.items).flat()

            setAreas(curriculums.filter(curriculum => curriculum.items.length > 0).map(curriculum => {
                const { items, ...curriculumData } = curriculum
                return curriculumData
            }))

            setSubjects(_subjects.filter(subject => subject.items.length > 0).map(subject => {
                const { items, ...subjectData } = subject
                return subjectData
            }))

            setMaterials(_materials.map(material => {
                const { items, ...materialData } = material
                return materialData
            }))
        }
    }, [curriculums]);

    const setCurriculum = (material: number) => {
        setValue("curriculum", material)
        setSelectedMaterial(material)
    }

    const onSubmit: SubmitHandler<IAssignLearningRequestForm> = async (data, event) => {
        event?.preventDefault()
        const timestamp = Date.now().toString()
        const secret = generateHmac(timestamp)

        const payload: IAssignLearningRequestBody = {
            email: user.email,
            role: user.role!,
            timestamp,
            secret,
            ...data
        }

        const response = await post<IGenericResponseWithStatus, IAssignLearningRequestBody>(REQUEST_TYPE.ASSIGN_LEARNING, payload)

        if (response.body.code === 200) {
            onSuccess()
        }
    }

    if (areas?.length === 0 && subjects.length === 0 && materials.length === 0) {
        return null
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Typeahead
                className="ion-margin-bottom"
                items={areas?.map(area => ({ text: area.name, value: area.id.toString() }))}
                label="Area"
                placeholder="Select Area"
                selectedItem={selectedArea?.toString()}
                onSelectionChange={(item) => setSelectedArea(parseInt(item, 10))}
            />
            {selectedArea && (
                <Typeahead
                    className="ion-margin-bottom"
                    items={subjects?.filter(subject => subject.parent === selectedArea).map(area => ({
                        text: area.name,
                        value: area.id.toString()
                    }))}
                    label="Subject"
                    placeholder="Select Subject"
                    selectedItem={selectedSubject?.toString()}
                    onSelectionChange={(item) => setSelectedSubject(parseInt(item, 10))}
                />
            )}
            {selectedSubject && (
                <Typeahead
                    className="ion-margin-bottom"
                    items={materials.filter(material => material.parent === selectedSubject).map(material => ({
                        text: material.name,
                        value: material.id.toString()
                    }))}
                    label="Material"
                    placeholder="Select Material"
                    selectedItem={selectedMaterial?.toString()}
                    onSelectionChange={(item) => setCurriculum(parseInt(item, 10))}
                />
            )}
            {selectedMaterial && (
                <>
                    <Typeahead
                        className="ion-margin-bottom"
                        items={teachers.map(teacher => ({ text: teacher.name, value: teacher.email }))}
                        label="Teacher"
                        placeholder="Select Teacher"
                        selectedItem={getValues("teacher")}
                        onSelectionChange={(item) => setValue("teacher", item)}
                    />

                    <IonInput
                        className={`ion-margin-bottom ${formState.errors.date && 'ion-invalid'} ${formState.touchedFields.date && 'ion-touched'}`}
                        fill="outline"
                        label="Date"
                        labelPlacement="floating"
                        placeholder="Date"
                        type="date"
                        errorText={formState.errors.date?.message}
                        defaultValue={moment().format('YYYY-MM-DD')}
                        {...register("date")}
                    />

                    <IonButton className="ion-activate" expand="block" type="submit">Save</IonButton>
                </>
            )}
        </form>
    )
}