import { forwardRef, HTMLAttributes, useState } from "react";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRadio,
    IonRadioGroup,
    IonSearchbar,
    IonTitle,
    IonToolbar,
    RadioGroupCustomEvent
} from "@ionic/react";
import { checkmarkOutline, checkmarkSharp, closeOutline, closeSharp } from "ionicons/icons";

export interface TypeAheadItem {
    text: string;
    value: string | number;
}

interface TypeaheadProps extends HTMLAttributes<HTMLIonInputElement> {
    items: TypeAheadItem[];
    title?: string;
    onSelectionCancel?: () => void;
    onSelectionChange?: (item: string) => void;
    selectedItem?: string
    showPreview?: boolean
    label: string
    placeholder?: string
    errorText?: string
}

const TypeaheadComponent = ({
                                label,
                                errorText,
                                placeholder,
                                selectedItem,
                                onSelectionCancel,
                                onSelectionChange,
                                items,
                                title,
                                showPreview,
                                ...inputProps
                            }: TypeaheadProps, ref: any
) => {
    const [filteredItems, setFilteredItems] = useState<TypeAheadItem[]>([...items]);
    const [workingSelectedValue, setWorkingSelectedValue] = useState<string>(selectedItem || "");
    const [showModal, setShowModal] = useState(false)
    const [preview, setPreview] = useState(items.find(item => item.value === selectedItem)?.text || "")

    const cancelChanges = () => {
        onSelectionCancel?.();
        setShowModal(false)
    };

    const confirmChanges = () => {
        onSelectionChange?.(workingSelectedValue);
        setPreview(items.find(item => item.value === workingSelectedValue)?.text!)
        setShowModal(false)
    };

    const searchbarInput = (ev: any) => {
        filterList(ev.target.value);
    };

    /**
     * Update the rendered view with
     * the provided search query. If no
     * query is provided, all data
     * will be rendered.
     */
    const filterList = (searchQuery: string | null | undefined) => {
        /**
         * If no search query is defined,
         * return all options.
         */
        if (searchQuery === undefined || searchQuery === null) {
            setFilteredItems([...items]);
        } else {
            /**
             * Otherwise, normalize the search
             * query and check to see which items
             * contain the search query as a substring.
             */
            const normalizedQuery = searchQuery.toLowerCase();
            setFilteredItems(
                items.filter((item) => {
                    return item.text.toLowerCase().includes(normalizedQuery) || (typeof item.value === "string" && item.value.toLowerCase().includes(normalizedQuery));
                })
            );
        }
    };

    const checkboxChange = (ev: RadioGroupCustomEvent) => {
        const { value } = ev.detail;
        setWorkingSelectedValue(value);
    }

    return (
        <>
            <IonInput
                fill="outline"
                label={label}
                labelPlacement="floating"
                placeholder={placeholder}
                errorText={errorText}
                onFocus={() => setShowModal(true)}
                value={preview}
                ref={ref}
                {...inputProps}
            />

            <IonModal isOpen={showModal}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton onClick={cancelChanges}>
                                <IonIcon icon={closeOutline} ios={closeSharp}/>
                            </IonButton>
                        </IonButtons>
                        <IonTitle>{title}</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={confirmChanges}>
                                <IonIcon icon={checkmarkOutline} ios={checkmarkSharp} slot="end"/>
                                <IonLabel>Save</IonLabel>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <IonToolbar>
                        <IonSearchbar onIonInput={searchbarInput}></IonSearchbar>
                    </IonToolbar>
                </IonHeader>

                <IonContent color="light" class="ion-padding">
                    <IonList id="modal-list" inset={true}>
                        <IonRadioGroup value={workingSelectedValue} onIonChange={checkboxChange}>
                            {filteredItems.map((item) => (
                                <IonItem key={item.value}>
                                    <IonRadio value={item.value}>
                                        <IonLabel>
                                            {item.text}
                                            {showPreview && (<p>{item.value}</p>)}
                                        </IonLabel>
                                    </IonRadio>
                                </IonItem>
                            ))}
                        </IonRadioGroup>
                    </IonList>
                </IonContent>
            </IonModal>
        </>
    );
}

export const Typeahead = forwardRef(TypeaheadComponent)