import {
    IonButton,
    IonButtons,
    IonContent,
    IonFab,
    IonFabButton,
    IonHeader,
    IonIcon,
    IonModal,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { addOutline, addSharp, closeOutline, closeSharp } from "ionicons/icons";
import { useState } from "react";
import { ICurriculumRequestBody, ICurriculumRequestData } from "../../../../interfaces/request/CurriculumRequest";
import { CurriculumType } from "../../../../constants/CurriculumType";
import { getEnumLabel } from "../../../../helpers";
import { useAuth } from "../../../../providers/Auth.provider";
import { useFetch } from "../../../../hooks";
import { REQUEST_TYPE } from "../../../../constants";
import { useToast } from "../../../../providers/ToastProvider";
import { useCurriculums } from "../../../../providers/CurriculumsProvider";
import { CurriculumForm } from "./CurriculumForm";

type IProps = {
    disabled?: boolean
}

export const AddCurriculum = ({ disabled }: IProps) => {
    const [showModal, setShowModal] = useState(false)
    const { user, generateHmac } = useAuth()
    const { post } = useFetch()
    const { toast } = useToast()
    const { fetchCurriculums } = useCurriculums()

    const onSubmit = async (data: ICurriculumRequestData) => {
        const timestamp = Date.now().toString()
        const secret = generateHmac(timestamp)

        const payload: ICurriculumRequestBody = {
            email: user.email,
            role: user.role!,
            timestamp,
            secret,
            ...data
        }
        const response = await post<any, ICurriculumRequestBody>(REQUEST_TYPE.ADD_CURRICULUM, payload)

        if (response.body.code === 200) {
            toast(`${getEnumLabel(CurriculumType, payload.type)} ${payload.name} successfully added`)
            setShowModal(false)
            await fetchCurriculums()
        }
    }

    return (
        <>
            <IonFab vertical="bottom" horizontal="end">
                <IonFabButton disabled={disabled} onClick={() => setShowModal(true)}>
                    <IonIcon icon={addOutline} ios={addSharp}/>
                </IonFabButton>
            </IonFab>
            <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Add Curriculum</IonTitle>
                        <IonButtons slot="start">
                            <IonButton onClick={() => {
                                setShowModal(false)
                            }}>
                                <IonIcon icon={closeOutline} ios={closeSharp}/>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <CurriculumForm onSubmit={onSubmit}/>
                </IonContent>
            </IonModal>

        </>
    )
}