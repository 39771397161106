import {
    IonAvatar,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonTitle,
    IonToolbar,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import {
    bookOutline,
    bookSharp,
    folderOutline,
    folderSharp,
    homeOutline,
    homeSharp,
    logOutOutline,
    peopleOutline,
    peopleSharp,
    personOutline,
    personSharp
} from 'ionicons/icons';
import './Menu.css';
import { SelectRole } from "../SelectRole";
import { useAuth } from "../../providers/Auth.provider";

export interface MenuItem {
    roleLevel: number,
    iosIcon: string,
    mdIcon: string,
    title: string,
    url: string
}

export interface MenuCategory {
    roleLevel: number,
    iosIcon: string,
    mdIcon: string,
    title: string,
    itemList: MenuItem[]
}

const MENU_LIST: MenuCategory[] = [
    {
        roleLevel: 100,
        iosIcon: folderOutline,
        mdIcon: folderSharp,
        title: 'General',
        itemList: [
            {
                roleLevel: 100,
                iosIcon: homeOutline,
                mdIcon: homeSharp,
                title: 'Home',
                url: '/dashboard'
            },
            {
                roleLevel: 100,
                iosIcon: personOutline,
                mdIcon: personSharp,
                title: 'Profile',
                url: '/dashboard/profile'
            },
            // {
            //     roleLevel: 100,
            //     iosIcon: peopleOutline,
            //     mdIcon: peopleSharp,
            //     title: 'Students',
            //     url: '/dashboard/students'
            // }
        ]
    },
    {
        roleLevel: 30,
        iosIcon: folderOutline,
        mdIcon: folderSharp,
        title: 'Manage',
        itemList: [
            {
                roleLevel: 30,
                iosIcon: bookOutline,
                mdIcon: bookSharp,
                title: 'Curriculums',
                url: '/dashboard/curriculums'
            },
            {
                roleLevel: 30,
                iosIcon: peopleOutline,
                mdIcon: peopleSharp,
                title: 'Parents',
                url: '/dashboard/parents'
            },
            {
                roleLevel: 30,
                iosIcon: personOutline,
                mdIcon: personSharp,
                title: 'Students',
                url: '/dashboard/students'
            }
        ]
    },
    // {
    //     roleLevel: 100,
    //     iosIcon: settingsOutline,
    //     mdIcon: settingsSharp,
    //     title: 'Settings',
    //     itemList: [
    //         {
    //             roleLevel: 30,
    //             iosIcon: notificationsOffOutline,
    //             mdIcon: notificationsSharp,
    //             title: 'Notification',
    //             url: '/page/Notification'
    //         }
    //     ]
    // },
]

const Menu = () => {
    const location = useLocation()
    const { user, signOut } = useAuth()

    const _signOut = async () => {
        await signOut()
        window.location.reload()
    }

    const name = user.name || user.email

    return (
        <IonMenu contentId="main" type="overlay">
            <IonHeader>
                <IonToolbar color="primary">
                    <IonTitle>
                        Rona Montessori
                    </IonTitle>
                </IonToolbar>
                <IonToolbar color="primary">
                    <div style={{
                        margin: '0 8px'
                    }}>
                        <IonItem color="primary">
                            <IonAvatar slot="start">
                                <img alt="Silhouette of a person's head"
                                     src="https://ionicframework.com/docs/img/demos/avatar.svg"/>
                            </IonAvatar>
                            <IonListHeader color="primary">{name}</IonListHeader>
                        </IonItem>
                    </div>
                </IonToolbar>
                <IonToolbar>
                    <SelectRole/>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList id="inbox-list">
                    {MENU_LIST.map((menuCategory, index) => {
                        if (user?.role! <= menuCategory.roleLevel) {
                            return (
                                <IonItemGroup key={index}>
                                    <IonItemDivider>
                                        <IonLabel>{menuCategory.title}</IonLabel>
                                    </IonItemDivider>
                                    {menuCategory.itemList.map((appPage, index) => {
                                        return (
                                            <IonMenuToggle key={index} autoHide={false}>
                                                <IonItem className={location.pathname === appPage.url ? 'selected' : ''}
                                                         routerLink={appPage.url} routerDirection="forward" lines="none"
                                                         detail={false}>
                                                    <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon}/>
                                                    <IonLabel>{appPage.title}</IonLabel>
                                                </IonItem>
                                            </IonMenuToggle>
                                        );
                                    })}
                                </IonItemGroup>
                            )
                        } else {
                            return ''
                        }
                    })}

                </IonList>

                <IonItem lines="none" button onClick={_signOut}>
                    <IonIcon slot="start" icon={logOutOutline}/>
                    <IonLabel>Sign Out</IonLabel>
                </IonItem>
            </IonContent>
        </IonMenu>
    );
};

export default Menu;
