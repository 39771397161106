import { SubmitHandler, useForm } from "react-hook-form";
import { IParentDetails } from "../../../../interfaces/response";
import { IParentUpdateRequestBody } from "../../../../interfaces/request";
import { IonButton, IonInput, IonSelect, IonSelectOption, IonTextarea } from "@ionic/react";
import { enumToSelectOptions } from "../../../../helpers";
import { Gender, REQUEST_TYPE } from "../../../../constants";
import { useLoading } from "../../../../providers/Loading.provider";
import { useAuth } from "../../../../providers/Auth.provider";
import { useFetch } from "../../../../hooks";
import { IParentUpdateResponse } from "../../../../interfaces/response/ParentUpdateResponse";
import { useToast } from "../../../../providers/ToastProvider";

type IProps = {
    parent: { name: string } & IParentDetails
    onSuccess: () => void
}

export const EditParent = (props: IProps) => {
    const { isLoading } = useLoading()
    const { user, generateHmac } = useAuth()
    const { post } = useFetch()
    const { toast } = useToast()
    const {
        register,
        handleSubmit,
        reset
    } = useForm<IParentUpdateRequestBody>({
        defaultValues: {
            parent: props.parent.parent,
            gender: props.parent.gender.toString(),
            birthdate: props.parent.birthdate,
            religion: props.parent.religion,
            address: props.parent.address,
            phone: props.parent.phone,
            education: props.parent.education,
            profession: props.parent.profession,
            company: props.parent.company,
            companyAddress: props.parent.companyAddress,
            companyPhone: props.parent.companyPhone,
            salary: props.parent.salary
        }
    })

    const onSubmit: SubmitHandler<IParentUpdateRequestBody> = async (data, event) => {
        event?.preventDefault()
        const timestamp = Date.now().toString()
        const secret = generateHmac(timestamp)
        const payload: IParentUpdateRequestBody = {
            ...data,
            email: user.email,
            role: user.role!,
            timestamp,
            secret
        }

        const response = await post<IParentUpdateResponse, IParentUpdateRequestBody>(REQUEST_TYPE.UPDATE_PARENT, payload)

        if (response.body.code === 200) {
            toast(`Parent ${props.parent.name} successfully updated`)
            reset()
            props.onSuccess()
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <IonInput
                disabled
                className={`ion-margin-bottom`}
                label="Email"
                value={props.parent.parent}
                fill="outline"
                labelPlacement="floating"
            />
            <IonInput
                disabled
                className={`ion-margin-bottom`}
                label="Name"
                value={props.parent.name}
                fill="outline"
                labelPlacement="floating"
            />
            <IonSelect
                interface="popover"
                className={`ion-margin-bottom`}
                fill="outline"
                label="Gender"
                labelPlacement="floating"
                placeholder="Gender"
                {...register('gender')}
            >
                {enumToSelectOptions(Gender).map(option => (
                    <IonSelectOption value={option.value}
                                     key={option.text.toLowerCase()}>{option.text}</IonSelectOption>
                ))}
            </IonSelect>
            <IonInput
                className={`ion-margin-bottom`}
                fill="outline"
                label="Birth Date"
                type="date"
                labelPlacement="floating"
                placeholder="Birth Date"
                {...register("birthdate")}
            />
            <IonInput
                className={`ion-margin-bottom`}
                fill="outline"
                label="Religion"
                labelPlacement="floating"
                placeholder="Religion"
                {...register("religion")}
            />
            <IonInput
                className={`ion-margin-bottom`}
                fill="outline"
                label="Education"
                labelPlacement="floating"
                placeholder="Education"
                {...register("education")}
            />
            <IonInput
                className={`ion-margin-bottom`}
                fill="outline"
                label="Profession"
                labelPlacement="floating"
                placeholder="Profession"
                {...register("profession")}
            />
            <IonTextarea
                className={`ion-margin-bottom`}
                fill="outline"
                label="Address"
                labelPlacement="floating"
                placeholder="Home Address"
                {...register("address")}
            />
            <IonInput
                className={`ion-margin-bottom`}
                fill="outline"
                type="tel"
                label="Phone"
                labelPlacement="floating"
                placeholder="Phone"
                {...register("phone")}
            />
            <IonInput
                className={`ion-margin-bottom`}
                fill="outline"
                label="Company"
                labelPlacement="floating"
                placeholder="Company"
                {...register("company")}
            />
            <IonTextarea
                className={`ion-margin-bottom`}
                fill="outline"
                label="Company Address"
                labelPlacement="floating"
                placeholder="Company Address"
                {...register("companyAddress")}
            />
            <IonInput
                className={`ion-margin-bottom`}
                fill="outline"
                type="tel"
                label="Company Phone"
                labelPlacement="floating"
                placeholder="Company Phone"
                {...register("companyPhone")}
            />

            <IonInput
                className={`ion-margin-bottom`}
                fill="outline"
                type="number"
                label="Salary"
                labelPlacement="floating"
                placeholder="Salary"
                {...register("salary")}
            />
            <IonButton className="ion-activable" expand="block" type="submit" disabled={isLoading}>
                Save
            </IonButton>
        </form>
    )
}