import { IParentDetails } from "../interfaces/response";
import { StudentDetailsBody } from "../interfaces/data/StudentDetails";
import { IonItem, IonLabel, IonList, IonListHeader } from "@ionic/react";
import { getEnumLabel } from "../helpers";
import { Gender } from "../constants";
import { chevronForwardOutline } from "ionicons/icons";
import moment from "moment";

type IProps = {
    parent: { name: string } & IParentDetails
    students?: StudentDetailsBody[]
}

export const ParentProfile = ({ parent, students }: IProps) => {
    return (
        <IonList lines="none">
            <IonItem>
                <IonLabel>
                    <h3>Email</h3>
                    <p><a href={`mailto:${parent?.parent}`}>{parent?.parent}</a></p>
                </IonLabel>
            </IonItem>
            <IonItem>
                <IonLabel>
                    <h3>Name</h3>
                    <p>{parent?.name}</p>
                </IonLabel>
            </IonItem>
            <IonItem>
                <IonLabel>
                    <h3>Gender</h3>
                    <p>{getEnumLabel(Gender, parent?.gender!, "Not Set")}</p>
                </IonLabel>
            </IonItem>
            <IonItem>
                <IonLabel>
                    <h3>Birth date</h3>
                    <p>{parent?.birthdate && moment(parent?.birthdate).format('DD MMMM YYYY')}</p>
                </IonLabel>
            </IonItem>
            <IonItem>
                <IonLabel>
                    <h3>Religion</h3>
                    <p>{parent?.religion}</p>
                </IonLabel>
            </IonItem>
            <IonItem>
                <IonLabel>
                    <h3>Phone</h3>
                    <p><a href={`tel:${parent?.phone}`}>{parent?.phone}</a></p>
                </IonLabel>
            </IonItem>
            <IonItem>
                <IonLabel>
                    <h3>Education</h3>
                    <p>{parent?.education}</p>
                </IonLabel>
            </IonItem>

            <IonListHeader className="ion-margin-top" lines="inset">
                <IonLabel>Residence</IonLabel>
            </IonListHeader>
            <IonItem>
                <IonLabel>
                    <h3>Home Address</h3>
                    <p>{parent?.address}</p>
                </IonLabel>
            </IonItem>

            <IonListHeader className="ion-margin-top" lines="inset">
                <IonLabel>Profession</IonLabel>
            </IonListHeader>
            <IonItem>
                <IonLabel>
                    <h3>Office</h3>
                    <p>{parent?.company}</p>
                </IonLabel>
            </IonItem>
            <IonItem>
                <IonLabel>
                    <h3>Profession</h3>
                    <p>{parent?.profession}</p>
                </IonLabel>
            </IonItem>
            {(parent?.salary! > 0) && (
                <IonItem>
                    <IonLabel>
                        <h3>Salary</h3>
                        <p>{parent?.salary}</p>
                    </IonLabel>
                </IonItem>
            )}
            <IonItem>
                <IonLabel>
                    <h3>Office Address</h3>
                    <p>{parent?.companyAddress}</p>
                </IonLabel>
            </IonItem>
            <IonItem>
                <IonLabel>
                    <h3>Office Phone</h3>
                    <p><a href={`tel:${parent?.companyPhone}`}>{parent?.companyPhone}</a></p>
                </IonLabel>
            </IonItem>

            {students && students?.length > 0 && (
                <>
                    <IonListHeader className="ion-margin-top" lines="inset">
                        <IonLabel>Student</IonLabel>
                    </IonListHeader>
                    <IonItem>
                        <IonList lines="full" style={{
                            width: '100%'
                        }}>
                            {students?.map((_student, index) => (
                                <IonItem key={index} button detail detailIcon={chevronForwardOutline} routerLink={`/dashboard/students/${_student.id}`}>
                                    <IonLabel>
                                        <h3>{_student.name}</h3>
                                        <p>{_student.birthdate && moment(_student.birthdate).format('DD MMMM YYYY')}</p>
                                    </IonLabel>
                                </IonItem>
                            ))}
                        </IonList>
                    </IonItem>
                </>
            )}
        </IonList>
    )
}