import JSCookies from "js-cookie"
const cookieOptions: JSCookies.CookieAttributes = {
    domain: window.location.hostname,
    secure: true
}

export const Cookies = {
    getCookie: (name: string) => {
        return JSCookies.get(name)
    },
    getAll: () => {
        return JSCookies.get()
    },
    setCookie: (name: string, value: string) => {
        JSCookies.set(name, value, cookieOptions)
    },
    clear: () => {
        const cookies = JSCookies.get()
        Object.keys(cookies).forEach(name => JSCookies.remove(name, cookieOptions))
    }
}