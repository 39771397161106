import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { ILearningBody, ILearningResponse, IStudent, IStudentResponse } from "../interfaces/response";
import { useAuth } from "./Auth.provider";
import { useFetch } from "../hooks";
import { ILearningRequestBody, IStudentRequestBody } from "../interfaces/request";
import { REQUEST_TYPE } from "../constants";

type IStudentContext = {
    students: IStudent[]
    fetchStudents: () => Promise<void>
    learnings: ILearningBody[]
    fetchLearnings: (studentId: number) => Promise<void>
    clearLearnings: () => void
    getStudent: (studentId: number) => IStudent | undefined
}

const StudentContext = createContext<IStudentContext>({} as IStudentContext)

export const useStudent = () => useContext(StudentContext)

export const StudentProvider = (props: PropsWithChildren<any>) => {
    const [students, setStudents] = useState<IStudent[]>([])
    const [learnings, setLearnings] = useState<ILearningBody[]>([])
    const { generateHmac, user } = useAuth()

    const { post } = useFetch()

    useEffect(() => {
        return () => {
            setStudents([])
            setLearnings([])
        }
    }, []);

    const fetchStudents = async () => {
        const timestamp = Date.now().toString()
        const secret = generateHmac(timestamp)

        const response = await post<IStudentResponse, IStudentRequestBody>(REQUEST_TYPE.GET_STUDENT, {
            email: user.email,
            role: user.role!,
            timestamp,
            secret
        })

        setStudents(JSON.parse(response?.body))
    }

    const fetchLearnings = async (studentId: number) => {
        const timestamp = Date.now().toString()
        const secret = generateHmac(timestamp)

        const response = await post<ILearningResponse, ILearningRequestBody>(REQUEST_TYPE.SEARCH_LEARNINGS, {
            email: user.email,
            role: user.role!,
            secret,
            student: studentId,
            timestamp
        })

        setLearnings(JSON.parse(response?.body))
    }

    const getStudent = (studentId: number) => {
        return students.find(student => student.id.toString() === studentId.toString())
    }

    return (
        <StudentContext.Provider value={{
            students,
            fetchStudents,
            learnings,
            fetchLearnings,
            clearLearnings: () => setLearnings([]),
            getStudent,
        }}>
            {props.children}
        </StudentContext.Provider>
    )
}