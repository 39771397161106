import { useStudent } from "../../../providers/StudentsProvider";
import { FC, useEffect, useState } from "react";
import { ILearningBody, IStudent } from "../../../interfaces/response";
import {
    IonContent,
    IonIcon,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonList,
    IonListHeader,
    IonNote
} from "@ionic/react";
import moment from "moment";
import { chevronForwardOutline, pencilOutline, pencilSharp, trashOutline, trashSharp } from "ionicons/icons";
import { LearningModal } from "./components/LearningModal";
import { RouteComponentProps } from "react-router-dom";
import { AssignLearning } from "./components/AssignLearning";

interface UserDetailPageProps
    extends RouteComponentProps<{
        id: string;
    }> {
}

export const StudentDetails: FC<UserDetailPageProps> = ({ match }) => {
    const [studentDetails, setStudentDetails] = useState<IStudent>()
    const [selectedLearning, setSelectedLearning] = useState<ILearningBody | undefined>()
    const { learnings, fetchLearnings, clearLearnings, getStudent } = useStudent()

    useEffect(() => {
        (async () => {
            setStudentDetails(getStudent(parseInt(match.params.id, 10)))
            await fetchLearnings(parseInt(match.params.id, 10))
        })()

        return () => {
            clearLearnings()
            setStudentDetails(undefined)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onAssignLearningSuccess = async () => {
        await fetchLearnings(parseInt(match.params.id, 10))
    }

    const getAge = (birthDate?: string): string => {
        if (!birthDate) {
            return `0 years`
        }

        const birth = moment(birthDate, 'YYYY-MM-DD');
        const now = moment();

        const years = now.diff(birth, 'years');
        birth.add(years, 'years');

        const months = now.diff(birth, 'months');
        birth.add(months, 'months');

        const days = now.diff(birth, 'days');

        return `${years} years ${months} months ${days} days`
    }

    return (
        <>
            <IonContent className="ion-padding">
                <h2>Student Profile</h2>
                <IonList lines="none">
                    <IonItem>
                        <IonLabel>
                            <h3>Name</h3>
                            <p>{studentDetails?.name}</p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                            <h3>Age</h3>
                            <p>{getAge(studentDetails?.birthdate)}</p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                            <h3>Birth date</h3>
                            <p>{moment(studentDetails?.birthdate, 'YYYY-MM-DD').format('D MMMM YYYY')}</p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                            <h3>Parent Name</h3>
                            <p>{studentDetails?.parentName}</p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                            <h3>Parent email</h3>
                            <p><a href={`mailto:${studentDetails?.parent}`}>{studentDetails?.parent}</a></p>
                        </IonLabel>
                    </IonItem>


                    <>
                        <IonListHeader className="ion-margin-top" lines="inset" key="header">
                            <IonLabel>Learning Path</IonLabel>
                            <AssignLearning studentName={studentDetails?.name!} studentId={studentDetails?.id!} onSuccess={onAssignLearningSuccess}/>
                        </IonListHeader>
                        {learnings && learnings.length > 0 && (
                            <IonItem key="item">
                                <IonList lines="full" style={{
                                    width: '100%'
                                }}>
                                    {learnings.map(learning => (
                                        <IonItemSliding key={learning.id}>
                                            <IonItem button detail detailIcon={chevronForwardOutline}
                                                     onClick={() => setSelectedLearning(learning)}>
                                                <IonLabel>
                                                    <h3>{learning.curriculumName}</h3>
                                                    <p>{learning.curriculumArea} - {learning.curriculumSubject}</p>
                                                </IonLabel>
                                                <div className="metadata-end-wrapper">
                                                    <IonNote>{moment(learning.time).format('D MMM YYYY')}</IonNote>
                                                </div>
                                                <IonItemOptions slot="end">
                                                    <IonItemOption>
                                                        <IonIcon slot="icon-only" icon={pencilOutline}
                                                                 ios={pencilSharp}/>
                                                    </IonItemOption>
                                                    <IonItemOption color="danger">
                                                        <IonIcon slot="icon-only" icon={trashOutline} ios={trashSharp}/>
                                                    </IonItemOption>
                                                </IonItemOptions>
                                            </IonItem>
                                        </IonItemSliding>
                                    ))}
                                </IonList>
                            </IonItem>
                        )}
                    </>
                </IonList>
            </IonContent>
            <LearningModal setLearning={setSelectedLearning} learning={selectedLearning}/>
        </>
    )
}