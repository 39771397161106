import { createContext, PropsWithChildren, useContext, useState } from "react";

type ILoadingContext = {
    isLoading: boolean
    setIsLoading: (isLoading: boolean) => void
}

const LoadingContext = createContext<ILoadingContext>({
    isLoading: false,
    setIsLoading: () => {}
})

export const useLoading = () => useContext(LoadingContext)

export const LoadingProvider = ({ children }: PropsWithChildren<any>) => {
    const [isLoading, setLoading] = useState(false)

    return (
        <LoadingContext.Provider value={{
            isLoading, setIsLoading: setLoading
        }}>
            {children}
        </LoadingContext.Provider>
    )
}