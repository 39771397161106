import { FC } from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import { PageWrapper } from "../../../components/PageWrapper";
import { IonRouterOutlet } from "@ionic/react";
import { CurriculumsList } from "./List";

export const CurriculumsIndex: FC<RouteComponentProps> = ({ match }) => {
    return (
        <PageWrapper name="Curriculums">
            <IonRouterOutlet>
                <Route path={`${match.url}`} component={CurriculumsList} exact/>
            </IonRouterOutlet>
        </PageWrapper>
    )
}