import { FC, ReactNode } from "react";
import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton, IonPage,
    IonProgressBar,
    IonTitle,
    IonToolbar, useIonRouter
} from "@ionic/react";
import { useLoading } from "../providers/Loading.provider";

interface IProps {
    name: string
    children: ReactNode
}

export const PageWrapper: FC<IProps> = (props: IProps) => {
    const { routeInfo: { pathname } } = useIonRouter()
    const { isLoading } = useLoading()

    const isSubPage = pathname.split('/').filter(path => path).length > 2

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        {isSubPage ? <IonBackButton defaultHref="#" /> : <IonMenuButton />}
                    </IonButtons>
                    <IonTitle>{props.name}</IonTitle>
                    {isLoading && (
                        <IonProgressBar type="indeterminate"/>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                {/*<IonHeader collapse="condense">*/}
                {/*    <IonToolbar>*/}
                {/*        <IonTitle size="large">{props.name}</IonTitle>*/}
                {/*        {isLoading && (*/}
                {/*            <IonProgressBar type="indeterminate"/>*/}
                {/*        )}*/}
                {/*    </IonToolbar>*/}
                {/*</IonHeader>*/}
                <IonContent className="ion-padding">
                {props.children}
                </IonContent>
            </IonContent>
        </IonPage>
    )
}