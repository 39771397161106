import styled from "styled-components";
import { IonLabel } from "@ionic/react";

const Container = styled.div`
    margin: 8px 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 100%;
    
    & img {
        margin: 16px;
    }
`

export const NoData = () => {
    return (
        <Container>
            <IonLabel>No data available</IonLabel>
            <img src="/assets/undraw_empty_re_opql.svg" alt="No Data" />
        </Container>
    )
}