import { useState } from "react";
import { IonButton, IonInput, IonSelect, IonSelectOption } from "@ionic/react";
import { enumToSelectOptions } from "../../../../helpers";
import { CurriculumType } from "../../../../constants/CurriculumType";
import { MarkdownEditor } from "../../../../components/MarkdownEditor";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { ICurriculumRequestData, ICurriculumRequestForm } from "../../../../interfaces/request/CurriculumRequest";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typeahead, TypeAheadItem } from "../../../../components/Typeahead";
import { useLoading } from "../../../../providers/Loading.provider";
import { useCurriculums } from "../../../../providers/CurriculumsProvider";

const schema = yup.object({
    name: yup.string().required("Name is required"),
    type: yup.number().required("Type is required"),
    parent: yup.string().when('type', {
        is: (value: number) => value > CurriculumType.Area,
        then: (schema) => schema.required('Parent is required'),
        otherwise: (schema) => schema.optional().nullable()
    }),
    description: yup.string().when('type', {
        is: (value: number) => {
            return value === CurriculumType.Material
        },
        then: (schema) => schema.required('Description is required'),
        otherwise: (schema) => schema.optional().nullable()
    })
})

type IProps = {
    isEdit?: boolean
    onSubmit: (data: ICurriculumRequestData) => Promise<void>
    defaultValues?: ICurriculumRequestForm
}

export const CurriculumForm = ({ isEdit, onSubmit, defaultValues }: IProps) => {
    const [type, _setType] = useState(isEdit ? defaultValues?.type : CurriculumType.Area)
    const { curriculums } = useCurriculums()
    const { isLoading } = useLoading()

    const _defaultValues = isEdit ? defaultValues : {
        type: CurriculumType.Area
    }

    const {
        register,
        handleSubmit,
        formState,
        getValues,
        setValue,
        reset
    } = useForm<ICurriculumRequestForm>({
        resolver: yupResolver(schema),
        defaultValues: _defaultValues
    })

    const _onSubmit: SubmitHandler<ICurriculumRequestForm> = async (data, event) => {
        event?.preventDefault()
        try {
            const payload = {
                ...data,
                parent: parseInt(data.parent!, 10)
            }

            await onSubmit(payload)
        } catch (e) {
            console.error(e)
        } finally {
            reset()
        }
    }

    const availableAreas: TypeAheadItem[] = curriculums.map(curriculum => ({
        value: curriculum.id,
        text: curriculum.name
    }))

    const availableSubject: TypeAheadItem[] = curriculums
        .map(curriculum => curriculum.items)
        .flat()
        .map(subject => ({
            value: subject.id,
            text: subject.name
        }))

    const setType = (type: CurriculumType) => {
        setValue('type', type)
        _setType(type)
    }

    return (
        <form onSubmit={handleSubmit(_onSubmit)}>
            <IonSelect
                className={`ion-margin-bottom ${formState.errors.type && 'ion-invalid'} ${formState.touchedFields.type && 'ion-touched'}`}
                fill="outline"
                label="Type"
                interface="popover"
                labelPlacement="floating"
                placeholder="Type"
                value={type}
                onIonChange={(event) => setType(event.target.value)}
            >
                {enumToSelectOptions(CurriculumType).map(option => (
                    <IonSelectOption value={option.value} key={option.text.toLowerCase()}>
                        {option.text}
                    </IonSelectOption>
                ))}
            </IonSelect>

            <IonInput
                className={`ion-margin-bottom ${formState.errors.name && 'ion-invalid'} ${formState.touchedFields.name && 'ion-touched'}`}
                fill="outline"
                label="Name"
                labelPlacement="floating"
                placeholder="Name"
                errorText={formState.errors.name?.message}
                {...register("name")}
            />

            {type && type === CurriculumType.Subject && (
                <Typeahead
                    className={`ion-margin-bottom ${formState.errors.parent && 'ion-invalid'} ${formState.touchedFields.parent && 'ion-touched'}`}
                    label="Area"
                    items={availableAreas}
                    placeholder="Select Area"
                    errorText={formState.errors.parent?.message}
                    selectedItem={getValues("parent")}
                    onSelectionChange={(item) => setValue("parent", item.toString())}
                />
            )}

            {type && type === CurriculumType.Material && (
                <Typeahead
                    className={`ion-margin-bottom ${formState.errors.parent && 'ion-invalid'} ${formState.touchedFields.parent && 'ion-touched'}`}
                    label="Material"
                    showPreview
                    items={availableSubject}
                    placeholder="Select Material"
                    errorText={formState.errors.parent?.message}
                    selectedItem={getValues("parent")}
                    onSelectionChange={(item) => setValue("parent", item.toString())}
                />
            )}
            {type === CurriculumType.Material && (
                <MarkdownEditor value={getValues('description')}
                                onChange={(value) => setValue('description', value)} label="Description"/>
            )}

            <IonButton className="ion-activable" expand="block" type="submit" disabled={isLoading}>
                Save
            </IonButton>
        </form>
    )
}