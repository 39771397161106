import { IonApp, IonPage, IonRouterOutlet, setupIonicReact, useIonRouter } from '@ionic/react';
import { Route } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { Login } from "./pages/Login";
import { DashboardIndex } from "./pages/Dashboard";
import { useEffect } from "react";
import { useAuth } from "./providers/Auth.provider";
import { useToast } from './providers/ToastProvider';

setupIonicReact();

const App = () => {
    const { push, routeInfo } = useIonRouter()
    const { isLoggedIn } = useAuth()
    const { toast } = useToast()
  
    useEffect(() => {
        const handleOffline = () => {
            toast("You are offline.")
        }

        const handleOnline = () => {
            toast("You are back online.")
        }

        window.addEventListener("offline", handleOffline)
        window.addEventListener("online", handleOnline)

        return () => {
            window.removeEventListener("offline", handleOffline)
            window.removeEventListener("online", handleOnline)
        }
    
    // eslint-disable-next-line
    },[])

    useEffect(() => {
        if (routeInfo.pathname === "/login") {
            if (isLoggedIn) {
                push("/dashboard", 'none', 'replace')
            }
        } else {
            if (!isLoggedIn) {
                push("/login", "none", "replace")
            }
        }
        // eslint-disable-next-line
    }, [isLoggedIn]);

    return (
        <IonApp>
            <IonPage>
                <IonRouterOutlet>
                    <Route path="/login" component={Login} exact/>
                    <Route path="/dashboard" component={DashboardIndex}/>
                </IonRouterOutlet>
            </IonPage>
        </IonApp>
    );
};

export default App;
