import { IonButton, IonContent, IonFooter, IonPage, IonProgressBar, IonRippleEffect } from "@ionic/react";
import "./Login.css"
import { useLogin } from "./state";
import { useLoading } from "../../providers/Loading.provider";

export const Login = () => {
    const { doLogin } = useLogin()
    const { isLoading,setIsLoading } = useLoading()

    const _doLogin = () => {
        setIsLoading(true)
        doLogin()
    }

    return (
        <IonPage>
            {isLoading && (
                <IonProgressBar type="indeterminate"/>
            )}
            <IonContent class="login-container-parent">
                <div className="login-container">
                    <img src="/assets/background.jpg" alt="Rona Montessori"/>
                    <div className="login-actions">
                        <IonButton fill="clear" onClick={_doLogin}>
                            <IonRippleEffect/>
                            <img src="/assets/btn_google_signin.png" alt="Sign in with Google"/>
                        </IonButton>
                    </div>
                </div>
            </IonContent>
            <IonFooter>
                <div className="login-info">
                    <p>Rona Montessori Web Application for <b>registered</b> employees (teacher) and parents to
                        manage students curriculums, their progress, activities, and reports.</p>
                    <p>While using this applications, you agree to have read and accepted our <a
                        href='/privacy.html' target={'_blank'}>privacy policy</a>.</p>
                </div>
            </IonFooter>
        </IonPage>
    )
}