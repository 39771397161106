import { PageWrapper } from "../../../components/PageWrapper";
import { ParentsList } from "./List";
import { ParentDetails } from "./ParentDetails";
import { IonRouterOutlet } from "@ionic/react";
import { FC } from "react";
import { Route, RouteComponentProps } from "react-router-dom";

export const ParentsIndex: FC<RouteComponentProps> = ({ match }) => {
    return (
        <PageWrapper name="Parents">
            <IonRouterOutlet>
                <Route path={`${match.url}/:email`} component={ParentDetails}
                       exact/>
                <Route path={`${match.url}`} component={ParentsList} exact/>
            </IonRouterOutlet>
        </PageWrapper>
    )
}