import { PageWrapper } from "../../../components/PageWrapper"
import { StudentList } from "./List"
import { StudentDetails } from "./Details";
import { IonRouterOutlet } from "@ionic/react";
import { Route, RouteComponentProps } from "react-router-dom";
import { FC } from "react";
import { StudentProvider } from "../../../providers/StudentsProvider";

export const StudentsIndex: FC<RouteComponentProps> = ({ match }) => {
    return (
        <StudentProvider>
            <PageWrapper name="Student">
                <IonRouterOutlet id="student">
                    <Route path={match.url} component={StudentList} exact/>
                    <Route path={`${match.url}/:id`} component={StudentDetails}
                           exact/>
                </IonRouterOutlet>
            </PageWrapper>
        </StudentProvider>
    )
}