import { useStudent } from "../../../providers/StudentsProvider";
import { useEffect, useState } from "react";
import { IonItem, IonLabel, IonList, IonRefresher, IonRefresherContent, IonSearchbar, IonToolbar } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";
import { AddStudent } from "./components/AddStudent";
import { NoData } from "../../../components/NoData";

export const StudentList = () => {
    const { students, fetchStudents } = useStudent()
    const [searchKeyword, setSearchKeyword] = useState<string>("")
    const _students = students.filter(
        student =>
            student.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            student.parentName.toLowerCase().includes(searchKeyword.toLowerCase())
    )

    useEffect(() => {
        (async () => {
            await fetchStudents()
        })()
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return (
        <>
            <IonRefresher slot="fixed" onIonRefresh={fetchStudents}>
                <IonRefresherContent/>
            </IonRefresher>
            <IonToolbar>
                <IonSearchbar debounce={100} onIonInput={(event) => setSearchKeyword(event?.target?.value!)}/>
            </IonToolbar>
            <IonList>
                {_students.length === 0 && (
                    <IonItem lines="none">
                        <NoData />
                    </IonItem>
                )}

                {_students.length > 0 && _students.map(student => (
                    <IonItem key={student.id} button detail detailIcon={chevronForwardOutline}
                             routerLink={`/dashboard/students/${student.id}`}>
                        <IonLabel>
                            <h3>{student.name}</h3>
                            <p>{student.parentName}</p>
                        </IonLabel>
                    </IonItem>
                ))}
            </IonList>
            <AddStudent/>
        </>
    )
}