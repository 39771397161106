import { PageWrapper } from "../../../components/PageWrapper";
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonGrid,
    IonInput,
    IonRow,
    IonText
} from "@ionic/react";
import moment from "moment";

import "./styles.css"

export const HomeIndex = () => {
    return (
        <PageWrapper name="Home">
            <IonGrid className="daily-report">
                <IonRow style={{
                    alignItems: 'center'
                }}>
                    <IonCol>
                        <IonText>
                            <h2>Daily Report</h2>
                        </IonText>
                    </IonCol>
                    <IonCol size="4">
                        <IonInput type="date" value={moment().format('YYYY-MM-DD')}/>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonCard className="card">
                            <IonCardHeader>
                                <IonCardTitle>Daily Activities</IonCardTitle>
                                <IonCardSubtitle>TEMA NYA APA</IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent className="content">
                                <IonGrid className="ion-no-padding">
                                    <IonRow className="ion-margin-bottom">
                                        <IonCol>
                                            <IonText>
                                                <h3>Area</h3>
                                                <p>Outdoor Activities, Indoor Activities</p>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-margin-bottom">
                                        <IonCol>
                                            <IonText>
                                                <h3>Apparatus</h3>
                                                <p>Games, Sholat Dhuha</p>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-margin-bottom">
                                        <IonCol>
                                            <IonText>
                                                <h3>Self Initiated Activities</h3>
                                                <p>Menyapu</p>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-margin-bottom">
                                        <IonCol className="assessment">
                                            <IonText>
                                                <h3>Assessments</h3>
                                            </IonText>
                                            <IonRow className="ion-margin-top ion-no-padding">
                                                <IonCol
                                                    className="ion-margin-bottom ion-padding-end assessment-content"
                                                    size="12" sizeXl="6">
                                                    <IonCard className="ion-no-padding ion-no-margin assessment-card">
                                                        <div className="thumbnail">
                                                            <img src="/assets/background.jpg" alt="thumbnail"/>
                                                        </div>
                                                        <IonCardContent>
                                                            Lorem ipsum dolor sit amet, consectetur adipisicing
                                                            elit. Esse in
                                                            ipsam nemo neque nisi non repellendus veritatis. Ad,
                                                            assumenda
                                                            cupiditate eveniet ex laudantium libero, maxime, modi
                                                            porro quia
                                                            quibusdam tempore.
                                                        </IonCardContent>
                                                    </IonCard>
                                                </IonCol>
                                                <IonCol
                                                    className="ion-margin-bottom ion-padding-end assessment-content"
                                                    size="12" sizeXl="6">
                                                    <IonCard className="ion-no-padding ion-no-margin assessment-card">
                                                        <div className="thumbnail">
                                                            <img src="/assets/background.jpg" alt="thumbnail"/>
                                                        </div>
                                                        <IonCardContent>
                                                            Lorem ipsum dolor sit amet, consectetur adipisicing
                                                            elit. Esse in
                                                            ipsam nemo neque nisi non repellendus veritatis. Ad,
                                                            assumenda
                                                            cupiditate eveniet ex laudantium libero, maxime, modi
                                                            porro quia
                                                            quibusdam tempore.
                                                        </IonCardContent>
                                                    </IonCard>
                                                </IonCol>
                                                <IonCol
                                                    className="ion-margin-bottom ion-padding-end assessment-content"
                                                    size="12" sizeXl="6">
                                                    <IonCard className="ion-no-padding ion-no-margin assessment-card">
                                                        <div className="thumbnail">
                                                            <img src="/assets/background.jpg" alt="thumbnail"/>
                                                        </div>
                                                        <IonCardContent>
                                                            Lorem ipsum dolor sit amet, consectetur adipisicing
                                                            elit. Esse in
                                                            ipsam nemo neque nisi non repellendus veritatis. Ad,
                                                            assumenda
                                                            cupiditate eveniet ex laudantium libero, maxime, modi
                                                            porro quia
                                                            quibusdam tempore.
                                                        </IonCardContent>
                                                    </IonCard>
                                                </IonCol>
                                                <IonCol
                                                    className="ion-margin-bottom ion-padding-end assessment-content"
                                                    size="12" sizeXl="6">
                                                    <IonCard className="ion-no-padding ion-no-margin assessment-card">
                                                        <div className="thumbnail">
                                                            <img src="/assets/background.jpg" alt="thumbnail"/>
                                                        </div>
                                                        <IonCardContent>
                                                            Lorem ipsum dolor sit amet, consectetur adipisicing
                                                            elit. Esse in
                                                            ipsam nemo neque nisi non repellendus veritatis. Ad,
                                                            assumenda
                                                            cupiditate eveniet ex laudantium libero, maxime, modi
                                                            porro quia
                                                            quibusdam tempore.
                                                        </IonCardContent>
                                                    </IonCard>
                                                </IonCol>
                                                <IonCol
                                                    className="ion-margin-bottom ion-padding-end assessment-content"
                                                    size="12" sizeXl="6">
                                                    <IonCard className="ion-no-padding ion-no-margin assessment-card">
                                                        <div className="thumbnail">
                                                            <img src="/assets/background.jpg" alt="thumbnail"/>
                                                        </div>
                                                        <IonCardContent>
                                                            Lorem ipsum dolor sit amet, consectetur adipisicing
                                                            elit. Esse in
                                                            ipsam nemo neque nisi non repellendus veritatis. Ad,
                                                            assumenda
                                                            cupiditate eveniet ex laudantium libero, maxime, modi
                                                            porro quia
                                                            quibusdam tempore.
                                                        </IonCardContent>
                                                    </IonCard>
                                                </IonCol>
                                                <IonCol
                                                    className="ion-margin-bottom ion-padding-end assessment-content"
                                                    size="12" sizeXl="6">
                                                    <IonCard className="ion-no-padding ion-no-margin assessment-card">
                                                        <div className="thumbnail">
                                                            <img src="/assets/background.jpg" alt="thumbnail"/>
                                                        </div>
                                                        <IonCardContent>
                                                            Lorem ipsum dolor sit amet, consectetur adipisicing
                                                            elit. Esse in
                                                            ipsam nemo neque nisi non repellendus veritatis. Ad,
                                                            assumenda
                                                            cupiditate eveniet ex laudantium libero, maxime, modi
                                                            porro quia
                                                            quibusdam tempore.
                                                        </IonCardContent>
                                                    </IonCard>
                                                </IonCol>

                                            </IonRow>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonGrid>

        </PageWrapper>
    )
}