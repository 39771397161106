import { createContext, PropsWithChildren, useContext, useState } from "react";
import { ICurriculumsItem, ICurriculumsItemRaw } from "../interfaces/data";
import { useAuth } from "./Auth.provider";
import { useFetch } from "../hooks";
import { IGenericResponse } from "../interfaces/response";
import { IGenericRequestBody } from "../interfaces/request";
import { REQUEST_TYPE } from "../constants";

type ICurriculumsContext = {
    curriculums: ICurriculumsItem[]
    fetchCurriculums: () => Promise<void>
    clearCurriculums: () => void
}

const CurriculumsContext = createContext<ICurriculumsContext>({} as ICurriculumsContext)

export const useCurriculums = () => useContext(CurriculumsContext)

export const CurriculumsProvider = ({ children }: PropsWithChildren<any>) => {
    const [curriculums, setCurriculums] = useState<ICurriculumsItem[]>([])

    const { user, generateHmac } = useAuth()
    const { post } = useFetch()

    const fetchCurriculums = async () => {
        const timestamp = Date.now().toString()
        const secret = generateHmac(timestamp)

        const response = await post<IGenericResponse, IGenericRequestBody>(REQUEST_TYPE.GET_CURRICULUMS, {
            email: user.email,
            role: user.role!,
            timestamp,
            secret
        })

        const curriculumsArray: ICurriculumsItemRaw[] = Object.values(JSON.parse(response?.body))

        const curriculums = curriculumsArray.map(curriculum => ({
            ...curriculum,
            items: Object.values(curriculum.items).map(curriculumItem => ({
                ...curriculumItem,
                items: Object.values(curriculumItem.items)
            }))
        }))

        setCurriculums(curriculums)
    }

    return (
        <CurriculumsContext.Provider value={{
            curriculums,
            fetchCurriculums,
            clearCurriculums: () => setCurriculums([])
        }}>
            {children}
        </CurriculumsContext.Provider>
    )
}