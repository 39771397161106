import { createContext, PropsWithChildren, useContext, useState } from "react";
import { IonToast, ToastButton } from "@ionic/react";
import { delay } from "../helpers/delay";

type IToastContext = {
    toast: (message: string) => void
}

const ToastContext = createContext<IToastContext>({} as IToastContext)

export const useToast = () => useContext(ToastContext)

const DEFAULT_DURATION: number = 3000

export const ToastProvider = ({ children }: PropsWithChildren<any>) => {
    const [message, setMessage] = useState("")
    const [isOpen, setIsOpen] = useState(false)
    const [duration, setDuration] = useState(DEFAULT_DURATION)
    const [position, setPosition] = useState<'top' | 'middle' | 'bottom' >('bottom')
    const [buttons, setButtons] = useState<ToastButton[]>([])

    const toast = (message: string, duration: number = DEFAULT_DURATION, position: 'top' | 'middle' | 'bottom' = 'bottom', dismissable?: boolean): void => {
        if (dismissable) {
            setButtons([{
                text: 'Dismiss',
                role: 'cancel',
                handler: () => {
                    setIsOpen(false)
                }
            }])
        }
        setMessage(message)
        setPosition(position)
        setDuration(duration)
        setIsOpen(true)
        delay(duration).then(() => {
            setIsOpen(false)
        })
    }

    return (
        <ToastContext.Provider value={{
            toast
        }}>
            {children}
            <IonToast
                isOpen={isOpen}
                position={position}
                message={message}
                onDidDismiss={() => setIsOpen(false)}
                duration={duration}
                buttons={buttons}
            />
        </ToastContext.Provider>
    )
}