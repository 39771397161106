import {
    ICurriculumRequestBody,
    ICurriculumRequestData,
    ICurriculumRequestForm
} from "../../../../interfaces/request/CurriculumRequest";
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { closeOutline, closeSharp } from "ionicons/icons";
import { CurriculumForm } from "./CurriculumForm";
import { useAuth } from "../../../../providers/Auth.provider";
import { useFetch } from "../../../../hooks";
import { useToast } from "../../../../providers/ToastProvider";
import { useCurriculums } from "../../../../providers/CurriculumsProvider";
import { REQUEST_TYPE } from "../../../../constants";
import { getEnumLabel } from "../../../../helpers";
import { CurriculumType } from "../../../../constants/CurriculumType";

type IProps = {
    show: boolean
    curriculum: ICurriculumRequestForm
    close: () => void
}

export const EditCurriculum = ({ curriculum, show, close }: IProps) => {
    const { user, generateHmac } = useAuth()
    const { post } = useFetch()
    const { toast } = useToast()
    const { fetchCurriculums } = useCurriculums()

    const onSubmit = async (data: ICurriculumRequestData) => {
        const timestamp = Date.now().toString()
        const secret = generateHmac(timestamp)

        const payload: ICurriculumRequestBody = {
            email: user.email,
            role: user.role!,
            timestamp,
            secret,
            ...data
        }
        const response = await post<any, ICurriculumRequestBody>(REQUEST_TYPE.UPDATE_CURRICULUM, payload)

        if (response.body.code === 200) {
            toast(`${getEnumLabel(CurriculumType, payload.type)} ${payload.name} successfully added`)
            close()
            await fetchCurriculums()
        }
    }

    return (
        <IonModal isOpen={show} onDidDismiss={() => close()}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Edit Curriculum</IonTitle>
                    <IonButtons slot="start">
                        <IonButton onClick={() => {
                            close()
                        }}>
                            <IonIcon icon={closeOutline} ios={closeSharp}/>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <CurriculumForm isEdit defaultValues={curriculum} onSubmit={onSubmit} />
            </IonContent>
        </IonModal>
    )
}