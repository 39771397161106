import { IonIcon } from "@ionic/react";
import {
    BlockTypeSelect,
    BoldItalicUnderlineToggles,
    Button,
    CreateLink,
    DiffSourceToggleWrapper,
    headingsPlugin, imagePlugin, InsertImage,
    linkDialogPlugin,
    listsPlugin,
    ListsToggle,
    MDXEditor,
    MDXEditorMethods,
    Separator,
    tablePlugin,
    toolbarPlugin,
    UndoRedo
} from "@mdxeditor/editor";
import { calendarOutline } from "ionicons/icons";
import moment from "moment";
import React from "react";
import styled from "styled-components";

type IProps = {
    value?: string
    onChange?: (markdown: string) => void
    label?: string
    imageUploadHandler?: (file: File) => Promise<string>
    mdxEditorRef?: any
}

const Container = styled.div`
    margin: 8px 0;
`

const Editor = styled(MDXEditor)`
    border: 1px solid #b3b3b3;
    border-radius: 8px;
`

const Label = styled.div`
    margin: 8px 4px;
`

export const MarkdownEditorExtended = ({ label, onChange, imageUploadHandler, value = "", mdxEditorRef}: IProps) => {
    const InsertDateToolbar: React.FC = () => {
        return (<Button
            onClick={() => {
                mdxEditorRef.current?.focus()
                const mddate = "### " + moment().format("DD MMMM YYYY");
                mdxEditorRef.current?.insertMarkdown(mddate);
            }}
        ><IonIcon icon={calendarOutline}/></Button>)
    }

    return (
        <Container>
            {label && (<Label>{label}</Label>)}
            <Editor
                ref={mdxEditorRef}
                markdown={value}
                onChange={(markdown) => onChange?.(markdown)}
                plugins={[
                    headingsPlugin(),
                    listsPlugin(),
                    tablePlugin(),
                    linkDialogPlugin(),
                    imagePlugin({
                        imageUploadHandler
                    }),
                    toolbarPlugin({
                        toolbarContents: () => (
                            <DiffSourceToggleWrapper>
                                <UndoRedo/>
                                <Separator/>
                                <InsertDateToolbar />
                                <BoldItalicUnderlineToggles/>
                                <Separator/>
                                <ListsToggle/>
                                <BlockTypeSelect/>
                                <Separator/>
                                <CreateLink/>
                                {imageUploadHandler && (<InsertImage />)}
                                <Separator/>
                            </DiffSourceToggleWrapper>
                        )
                    })
                ]}
            />
        </Container>
    )
}

export const MarkdownEditor = ({ label, onChange, imageUploadHandler, value = ""}: IProps) => {
    const mdxEditorRef = React.useRef<MDXEditorMethods>(null)
    return MarkdownEditorExtended({label, onChange, imageUploadHandler, value, mdxEditorRef})
}