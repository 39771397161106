export const SERVER = {
    HOST: 'https://dev.rona.id/api',
    UPLOAD_FILE: 'https://dev.rona.id/gallery/upload.php'
}

export const REQUEST_TYPE = {
    CHECK_TOKEN: "rona.app.authentication.check",
    GET_PARENTS: "rona.app.parent.get",
    GET_PARENT_DETAILS: "rona.app.parent.detail.get",
    ADD_PARENT: "rona.app.parent.new",
    UPDATE_PARENT: "rona.app.parent.detail.update",
    GET_STUDENT: "rona.app.student.get",
    ADD_STUDENT: "rona.app.student.new",
    SEARCH_LEARNINGS: "rona.app.learnings.search",
    GET_CURRICULUMS: "rona.app.curriculums.get",
    ADD_CURRICULUM: "rona.app.curriculums.new",
    UPDATE_CURRICULUM: "rona.app.curriculums.update",
    DELETE_CURRICULUM: "rona.app.curriculums.delete",
    ASSIGN_LEARNING: "rona.app.learnings.assign",
    UPDATE_LEARNING: "rona.app.learnings.update",
    GET_TEACHER: "rona.app.teacher.get"
}