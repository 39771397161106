import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { Cookies } from "../helpers";
import { COOKIE_NAMES, REQUEST_TYPE, ROLES, SERVER } from "../constants";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import { googleLogout } from "@react-oauth/google";
import { useFetch } from "../hooks";

type UserData = {
    roles: number[]
    role?: number
    roleName?: string
    email: string
    name: string
    secret: string
    session: string
}

type IAuthContext = {
    user: UserData
    setSession: (session: UserData) => void
    selectRole: (role: number) => void
    generateHmac: (timestamp: string) => string
    isLoggedIn: boolean
    signOut: () => Promise<void>
    getRoleName: (id: number) => string
}
const AuthContext = createContext<IAuthContext>({} as IAuthContext)

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }: PropsWithChildren<any>) => {
    const [roles, _setRoles] = useState<number[]>(JSON.parse(Cookies.getCookie(COOKIE_NAMES.ROLES) || "[]"))
    const [role, setRole] = useState<number>(parseInt(Cookies.getCookie(COOKIE_NAMES.SELECTED_ROLE)!, 10))
    const [email, _setEmail] = useState(Cookies.getCookie(COOKIE_NAMES.EMAIL) || "")
    const [name, _setName] = useState(Cookies.getCookie(COOKIE_NAMES.NAME) || "")
    const [secret, _setSecret] = useState(Cookies.getCookie(COOKIE_NAMES.SECRET) || "")
    const [session, _setSession] = useState(Cookies.getCookie(COOKIE_NAMES.SESSION) || "")

    const { post } = useFetch()

    const setSession = (session: UserData) => {
        Cookies.setCookie(COOKIE_NAMES.NAME, session.name)
        Cookies.setCookie(COOKIE_NAMES.EMAIL, session.email)
        Cookies.setCookie(COOKIE_NAMES.SECRET, session.secret)
        Cookies.setCookie(COOKIE_NAMES.SESSION, session.session)
        Cookies.setCookie(COOKIE_NAMES.ROLES, JSON.stringify(session.roles))

        _setRoles(session.roles)
        _setEmail(session.email)
        _setName(session.name)
        _setSecret(session.secret)
        _setSession(session.session)

        if (session.roles) {
            if (session.roles.length > 1) {
                const lowestRole = session.roles.reduce((max: number, role: number) => (role > max ? role : max), session.roles[0])
                _setRole(lowestRole)
            } else {
                _setRole(session.roles[0])
            }
        }
    }

    const _setRole = (id: number) => {
        setRole(id)
        Cookies.setCookie(COOKIE_NAMES.SELECTED_ROLE, id?.toString())
    }

    const generateHmac = (timestamp: string): string => {
        const hashTime = CryptoJS.SHA256(timestamp).toString(CryptoJS.enc.Hex)
        const hmac = CryptoJS.HmacSHA256(hashTime + session, secret)
        return hmac.toString(CryptoJS.enc.Hex)
    }

    const getCurrentRoleName = (): string => {
        return getRoleName(role)
    }

    const getRoleName = (id: number) => {
        switch (id) {
            case ROLES.TEACHER.CODE:
                return ROLES.TEACHER.NAME;
            case ROLES.PARENT.CODE:
                return ROLES.PARENT.NAME;
            default:
                return "N/A";
        }
    }

    const isLoggedIn = () => {
        return !!(email && session && secret && role)
    }

    const signOut = async () => {
        try {
            const timestamp = Date.now().toString()
            const hmac = generateHmac(timestamp)

            const request = {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    "id": uuidv4,
                    "type": "rona.app.authentication.logout",
                    "body": {
                        "email": email,
                        "timestamp": timestamp,
                        "secret": hmac,
                        "role": role,
                    }
                })
            }

            await fetch(SERVER.HOST, request)

            await googleLogout()

            Cookies.clear()
            window.location.reload()
        } catch (e) {
            console.error(e)
        }
    }

    const check = async () => {
        const timestamp = Date.now().toString()
        const _secret = generateHmac(timestamp)
        if (role && email && secret && session) {
            await post(REQUEST_TYPE.CHECK_TOKEN, {
                secret: _secret,
                email,
                role,
                timestamp
            })
        }
    }

    useEffect(() => {
        (async () => {
            await check()
        })()
        // eslint-disable-next-line
    }, []);

    return (
        <AuthContext.Provider value={{
            user: {
                roles,
                role,
                email,
                name,
                secret,
                session,
                roleName: getCurrentRoleName(),
            },
            setSession,
            selectRole: _setRole,
            generateHmac,
            isLoggedIn: isLoggedIn(),
            signOut,
            getRoleName,
        }}>
            {children}
        </AuthContext.Provider>
    )
}