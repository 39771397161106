import { useCurriculums } from "../../../providers/CurriculumsProvider";
import { useEffect, useState } from "react";
import {
    IonAccordion,
    IonAccordionGroup,
    IonBadge,
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonRefresher,
    IonRefresherContent,
    IonSearchbar,
    IonToolbar
} from "@ionic/react";
import Markdown from "marked-react";
import { pencilOutline, pencilSharp, trashOutline, trashSharp } from "ionicons/icons";
import { AddCurriculum } from "./components/AddCurriculum";
import { NoData } from "../../../components/NoData";
import { ICurriculumRequestForm } from "../../../interfaces/request/CurriculumRequest";
import { EditCurriculum } from "./components/EditCurriculum";
import { DeleteCurriculum } from "./components/DeleteCurriculum";

export const CurriculumsList = () => {
    const [searchKeyword, setSearchKeyword] = useState("")
    const [selectedCurriculum, setSelectedCurriculum] = useState<ICurriculumRequestForm | null>(null)
    const [showEditModal, _setShowEditModal] = useState(false)
    const [showDeleteModal, _setShowDeleteModal] = useState(false)
    const { curriculums: curriculumsData, fetchCurriculums, clearCurriculums } = useCurriculums()

    useEffect(() => {
        (async () => {
            await fetchCurriculums()
        })()
        return () => {
            clearCurriculums()
            setSelectedCurriculum(null)
        }
        // eslint-disable-next-line
    }, []);

    const setShowEditModal = (curriculum: ICurriculumRequestForm | null) => {
        setSelectedCurriculum(curriculum)
        _setShowEditModal(!!curriculum)
    }

    const setShowDeleteModal = (curriculum: ICurriculumRequestForm | null) => {
        setSelectedCurriculum(curriculum)
        _setShowDeleteModal(!!curriculum)
    }

    const curriculums = curriculumsData.filter(curriculum => curriculum.name.toLowerCase().includes(searchKeyword.toLowerCase()))

    return (
        <>
            <IonRefresher slot="fixed" onIonRefresh={fetchCurriculums}>
                <IonRefresherContent/>
            </IonRefresher>
            <IonToolbar>
                <IonSearchbar debounce={100} onIonInput={(event) => setSearchKeyword(event?.target?.value!)}/>
            </IonToolbar>
            <IonList>
                {curriculums.length === 0 && (
                    <IonItem lines="none">
                        <NoData/>
                    </IonItem>
                )}

                {curriculums.length > 0 && (
                    <IonAccordionGroup multiple={false}>
                        {curriculums.map(area => (
                            <IonAccordion key={area.id} value={area.id.toString()}>
                                <IonItem slot="header" button>
                                    <IonLabel>
                                        {area.name}
                                    </IonLabel>

                                    <div className="metadata-end-wrapper" slot="end" style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                        gap: 8
                                    }}>
                                        <IonButton
                                            color="secondary"
                                            onClick={() => setShowEditModal({
                                                id: area.id,
                                                name: area.name,
                                                type: area.type,
                                                description: area.desc!
                                            })}>
                                            <IonIcon slot="icon-only" icon={pencilOutline} ios={pencilSharp}/>
                                        </IonButton>
                                        {area?.items.length === 0 && (
                                            <IonButton color="danger"
                                                       onClick={() => setShowDeleteModal({
                                                           id: area.id,
                                                           name: area.name,
                                                           type: area.type,
                                                       })}>
                                                <IonIcon slot="icon-only" icon={trashOutline} ios={trashSharp}/>
                                            </IonButton>
                                        )}
                                        <IonBadge>{area?.items.length}</IonBadge>
                                    </div>
                                </IonItem>

                                {area?.items.length > 0 && (
                                    <IonAccordionGroup multiple={false} slot="content"
                                                       className="ion-padding-start ion-padding-end">
                                        {area?.items?.map(subject => (
                                            <IonAccordion key={`${area.id}-${subject.id}`}
                                                          value={subject.id.toString()}>
                                                <IonItem button slot="header">
                                                    <IonLabel>
                                                        {subject.name}
                                                    </IonLabel>

                                                    <div className="metadata-end-wrapper" slot="end" style={{
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        gap: 8
                                                    }}>
                                                        <IonButton
                                                            color="secondary"
                                                            onClick={() => setShowEditModal({
                                                                id: subject.id,
                                                                name: subject.name,
                                                                type: subject.type,
                                                                parent: subject.parent.toString(),
                                                                description: subject.desc!
                                                            })}>
                                                            <IonIcon slot="icon-only" icon={pencilOutline}
                                                                     ios={pencilSharp}/>
                                                        </IonButton>
                                                        {subject?.items.length === 0 && (
                                                            <IonButton color="danger"
                                                                       onClick={() => setShowDeleteModal({
                                                                           id: subject.id,
                                                                           name: subject.name,
                                                                           type: subject.type,
                                                                           parent: subject.parent.toString(),
                                                                           description: subject.desc!
                                                                       })}>
                                                                <IonIcon slot="icon-only" icon={trashOutline}
                                                                         ios={trashSharp}/>
                                                            </IonButton>
                                                        )}
                                                        <IonBadge>{subject?.items.length}</IonBadge>
                                                    </div>
                                                </IonItem>

                                                {subject?.items.length > 0 && (
                                                    <IonAccordionGroup multiple={false} slot="content"
                                                                       className="ion-padding-start ion-padding-end">
                                                        {subject?.items?.map(material => (
                                                            <IonAccordion
                                                                key={`${area.id}-${subject.id}-${material.id}`}
                                                                value={material.id.toString()}>
                                                                <IonItem button slot="header">
                                                                    <IonLabel>
                                                                        {material.name}
                                                                    </IonLabel>

                                                                    <div className="metadata-end-wrapper" slot="end"
                                                                         style={{
                                                                             justifyContent: 'center',
                                                                             alignItems: 'center',
                                                                             display: 'flex',
                                                                             gap: 8
                                                                         }}>
                                                                        <IonButton color="secondary"
                                                                                   onClick={() => setShowEditModal({
                                                                                       id: material.id,
                                                                                       name: material.name,
                                                                                       type: material.type,
                                                                                       parent: material.parent.toString(),
                                                                                       description: material.desc!
                                                                                   })}>
                                                                            <IonIcon slot="icon-only"
                                                                                     icon={pencilOutline}
                                                                                     ios={pencilSharp}/>
                                                                        </IonButton>
                                                                        <IonButton color="danger"
                                                                                   onClick={() => setShowDeleteModal({
                                                                                       id: material.id,
                                                                                       name: material.name,
                                                                                       type: material.type,
                                                                                       parent: material.parent.toString(),
                                                                                       description: material.desc!
                                                                                   })}>
                                                                            <IonIcon slot="icon-only"
                                                                                     icon={trashOutline}
                                                                                     ios={trashSharp}/>
                                                                        </IonButton>
                                                                    </div>
                                                                </IonItem>

                                                                {material.desc && (
                                                                    <IonItem slot="content">
                                                                        <IonLabel>
                                                                            <Markdown value={material.desc}/>
                                                                        </IonLabel>
                                                                    </IonItem>
                                                                )}
                                                            </IonAccordion>
                                                        ))}
                                                    </IonAccordionGroup>
                                                )}
                                            </IonAccordion>
                                        ))}
                                    </IonAccordionGroup>
                                )}
                            </IonAccordion>
                        ))}
                    </IonAccordionGroup>
                )}
            </IonList>
            <AddCurriculum disabled={curriculums.length === 0}/>
            <EditCurriculum show={showEditModal} curriculum={selectedCurriculum!} close={() => setShowEditModal(null)}/>
            <DeleteCurriculum curriculum={selectedCurriculum!} show={showDeleteModal} onConfirm={async () => {
                setShowDeleteModal(null)
                await fetchCurriculums()
            }} onCancel={() => setShowDeleteModal(null)}/>
        </>
    )
}
