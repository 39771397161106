import { IonSelect, IonSelectOption } from "@ionic/react";
import { useEffect, useState } from "react";
import { useAuth } from "../providers/Auth.provider";

type IRoleItem = {
    id: number
    name: string
}

export const SelectRole = () => {
    const [availableRoles, setAvailableRoles] = useState<IRoleItem[]>([])
    const { user, selectRole, getRoleName } = useAuth()

    const onRoleSelected = (id: number) => {
        selectRole(id)
    }

    useEffect(() => {
        if (user.roles) {
            const _availableRoles = user.roles.map((id: number) => ({
                id,
                name: getRoleName(id)
            }))

            setAvailableRoles(_availableRoles)

            if (_availableRoles.length > 1) {
                const lowestRole = _availableRoles.reduce((max: IRoleItem, role: IRoleItem) => (role?.id > max?.id ? role : max), _availableRoles[0])
                onRoleSelected(lowestRole?.id)
            } else {
                onRoleSelected(_availableRoles[0]?.id)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={{
            padding: '0 16px'
        }}>
        <IonSelect value={user.role} interface="popover" placeholder="Select Role" onIonChange={(e) => onRoleSelected(e.target.value)}>
            {availableRoles.map((role, index) => (
                <IonSelectOption value={role?.id} key={index}>{role.name}</IonSelectOption>
            ))}
        </IonSelect>
        </div>
    )
}